import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import FooterNew from '../component/FooterNew/Footer'
import ReasonedText from '../component/ReasonedText/ReasonedText'
import Contactform from '../component/Contact Form/Contactform'
import { Helmet } from 'react-helmet'

function RefusedVisa() {
  return (
    <div>
      <Helmet>
          <title> 
          Brampton Visa Refusal Consultancy | Common Reasons & Fraud Risks
          </title>
          <meta 
          name='description' 
          content="Discover common reasons for visa refusal and risks of document fraud with Brampton visa refusal consultancy. Ensure honesty with CIC to avoid misrepresentation."
          />
          
        </Helmet>
        <HeroBanner 
            subHeading=""
            Heading="Refused Visa"
            nextLineSpanHeading=""
            Paragraph="There are several reasons why applications of visa are refused. Contact us if your visa was refused. Let our consultant review your refused visa application."
            btnText=""
            bgImg="/images/creatives/refused-hero-bg.jpg"
            btn={false}
            btnRef="/"
            bgEffect={false}
            isCheveron={false}
        />
        <div className='left-gradient'>
            <ReasonedText/>
        </div>

        <div className='right-gradient-bottom'>
         <Contactform/>
        </div>

        <FooterNew/>
    </div>
  )
}

export default RefusedVisa