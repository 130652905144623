import React, { useEffect, useState } from 'react'
import "./KeyFeatureText.css"
import { useNavigate } from 'react-router-dom'
import codes from "./code"

const genralCode = `
<h3 class="body-heading">Immigration Services</h3>
<div class="container">
    <ul class="custom-green-bullet-list row gy-2">
    <div class="col-lg-6"><li class=" px-2"><p class="body-paragraph mb-0">Federal Skilled Worker Program</p></li></div>
    <div class="col-lg-6"><li class="px-2"><p class="body-paragraph mb-0">Canadian Experience Class.</p></li></div>
    <div class="col-lg-6"><li class="px-2"><p class="body-paragraph mb-0">Federal Skilled Trades Program</p></li></div>
    </ul>
</div>
<h3 class="body-heading mt-4">The Express Entry System has Two Steps:</h3>
<div class="d-flex align-items-start gap-4"><p class="step1-txt">Step1</p><p class="body-paragraph ">Potential candidates complete an online EXPRESS ENTRY PROFILE Potential candidates will complete an online Express Entry profile. This is a secure form that they will use to provide information about their: SKILLS, WORK, EXPERIENCE, LANGUAGE ABILITY, EDUCATION, and other details that will help us and CIC assess them. Those who meet the criteria of one of the federal immigration programs listed above will be accepted into a pool of candidates.</p></div>
<div class="d-flex align-items-start gap-4"><p class="step1-txt">Step2</p><p class="body-paragraph step2-txt">The high-ranking candidates in the pool will be invited to apply for permanent residence Candidates will be ranked against others in the pool using a point-based system called the</p></div>

<h3 class="body-heading mt-4">Comprehensive Ranking System (CRS)</h3>
<div class="container">
    <ul class="custom-green-bullet-list row gy-2">
    <div class="col-lg-6 "><li class="px-2"><p class="body-paragraph mb-0">Points are awarded using the information in their profile.</p></li></div>
    <div class="col-lg-6 "><li class="px-2"><p class="body-paragraph mb-0">A JOB OFFER, and/or</p></li></div>
    <div class="col-lg-6 "><li class="px-2"><p class="body-paragraph mb-0">Candidates with the highest scores in the pool will be issued an Invitation to Apply. Candidates will be awarded points for:</p></li></div>
    <div class="col-lg-6 "><li class="px-2"><p class="body-paragraph mb-0">A NOMINATION FROM PROVINCE or territory, and/or</p></li></div>
    </ul>
</div>

<h3 class="body-heading mt-4">Skilled & Experience Factors</h3>

<ul class="custom-green-bullet-list">
    <li><p class="body-paragraph ">A candidate can get additional points for:</p></li>
    <li><p class="body-paragraph ">A job offer supported by a Labour Market Impact Assessment, or A nomination by a province or</p></li>
    <li><p class="body-paragraph ">territory These additional points will make a candidate rank high enough to be invited to apply at the next eligible draw of candidates.</p></li>
    <li><p class="body-paragraph "> If someone is invited to apply, they will have 60 days to submit an online application for permanent residence.</p></li>
    <li><p class="body-paragraph ">Citizenship and Immigration Canada will process the majority of complete applications (meaning those with all the necessary supporting documents) in six months or less. </p></li>
    <li><p class="body-paragraph ">Candidates can stay in the pool for up to 12 months. If they do not get an Invitation to Apply for permanent residence within 12 months of submitting an Express Entry profile, they may submit a new profile. If they still meet the criteria, they can re-enter the pool. This will prevent backlogs and ensure quick processing times.</p></li>
</ul>
`


const changingTabData = [
    // {
    //     id:1,
    //     heading:"Federal Skilled Worker",
    //     url:"/immigration/federal-skilled-worker",
    //     code:genralCode
    // },
    // {
    //     id:2,
    //     heading:"Quebec Skilled Worker",
    //     url:"/immigration/quebec-skilled-worker",
    //     code:genralCode
    // },
    // {
    //     id:3,
    //     heading:"Investor Program",
    //     url:"/immigration/investor-program",
    //     code:genralCode
    // },
    // {
    //     id:4,
    //     heading:"Entrepreneur Program",
    //     url:"/immigration/entrepreneur-program",
    //     code:genralCode
    // },
    // {
    //     id:5,
    //     heading:"Self Employed Program",
    //     url:"/immigration/self-employed-program",
    //     code:genralCode
    // },
    {
        id:1,
        heading:"Refugee Cases",
        url:"/immigration/refugee-cases",
        code:codes.codeRefuge
    },
    {
        id:2,
        heading:"Refused Visa",
        url:"/immigration/refused-visa",
        code:codes.codeRefused
    },
    {
        id:3,
        heading:"Super Visa",
        url:"/immigration/super-visa",
        code:codes.codeSuper
    },
    {
        id:4,
        heading:"Sponsoring Spouse",
        url:"/immigration/sponsoring-spouse",
        code:codes.codeSpouse
    },
    {
        id:5,
        heading:"Work Permit",
        url:"/immigration/work-permit",
        code:codes.codeWork
    },
    {
        id:6,
        heading:"Visitors Visa",
        url:"/immigration/visitors-visa",
        code:codes.codeVisitor
    },
    {
        id:7,
        heading:"Live-in Caregivers",
        url:"/immigration/live-in-caregivers",
        code:codes.codeCare
    },
    {
        id:8,
        heading:"Student Visa",
        url:"/immigration/student-visa",
        code:codes.codeStudy
    },
    {
        id:9,
        heading:"PNP Program",
        url:"/immigration/pnp-program",
        code:codes.codePnp
    },
   
]

function KeyFeatureText({ind=1 }) {
    const [curElement , setCurElement] = useState(ind-1)
    useEffect(()=>{
        setCurElement(ind-1)

    },[ind])

    const navigate = useNavigate()
  return (
    <div className='KeyFeatureText-container standard-padding-space'>
        <div className='container'>
            <div className='row gy-3 flex-column-reverse flex-xl-row'>
                <div className='col-xl-5'>
                    <div className='d-flex flex-column main-box my-4 justify-content-center'>

                    {
                        changingTabData.map((ele,ind)=>{
                            return(
                                <div 
                                    className={`w-100 tab-box ${curElement === ind && "active-tab"} p-4`}
                                    key={ele.id}
                                    onClick={()=>{setCurElement(ind);navigate(ele.url)}}
                                >
                                    <h4 
                                        className={`body-heading mb-0  ${curElement === ind && "active-title"} `} 
                                        >{ele.heading}
                                    </h4>
                                </div>
                        )
                    })
                }
                </div>
               

                </div>
                <div className='col-xl-7'>
                    <div className='cont py-3' dangerouslySetInnerHTML={{__html:changingTabData[curElement].code}}></div>
                </div>

            </div>
                    
        </div>
    </div>
  )
}

export default KeyFeatureText