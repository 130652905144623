import React from 'react'
import "./CardContent.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";

function CardContent({
    subHeading,
    heading,
    content,
    spanHeading
  }) {
    const blogData = [
        {
          cardImg: "./images/creatives/about-card-1.png",
          title: "Colonel Rocky Mehta, RCIC ",
          nextLineTitle:'Founder & Director',
          content:
            "Colonel Rocky Mehta, a military veteran, is the founder CEO of the Company. He has about 30 years of experience in management at National and International level. He has served in South Asia, Middle East and United States as part of United Nations HR Staff. He holds a master's degree in Management and actively involved in settlement of new comers to Canada. He is also graduate in Immigration Consultants Course from Humber College, Brampton, Canada and member in good standing of ICCRC.",
        },
        
        {
          cardImg: "./images/creatives/about-card-2.png",
          title: "Rajiv Sharma, RCIC",
          content:
            "Rajiv Sharma is authorized by the Government of Canada to represent clients in their immigration applications with IRCC. He has over 15 years of experience as an Education Counsellor. He is a member in good standing of the College of Immigration and Citizenship Consultants(CICC). He has everything covered when it comes to Canadian Immigration Consultation including Express Entry as Permanent Residency applications, Student Permit, Work permit, Provincial Nominee Programs, LMIA, Visitor Visa, H&amp;C applications, Inadmissibility, Spousal and Family Sponsorship, Refugee claims, Business Immigration, Intra Company transfers, Working holidays, Citizenship, Caregiver applications and much more.",
          anchor:"https://www.vcicimmigration.com/"
        },
        
        {
          cardImg: "./images/creatives/about-card-3.jpg",
          title: "Varjinder Dhariwal, RCIC",
          content:
            "Varjinder Dhariwal, an immigration consultant with a B.Sc. and MBA in Finance. Since 2019, I've been dedicated to guiding clients through Canada's immigration landscape, leveraging my deep understanding gained from both academic pursuits and practical experience. I obtained my RCIC license in June 2022, further solidifying my commitment to ensuring seamless immigration journeys. Let's connect and explore how I can assist you in achieving your immigration goals effectively and with confidence.",
        },
        
        
      ];
    
      return (
        <div className='CardCarousel-container standard-padding-space container my-4'>
          <div className="blog-upper-content mb-4">
            <div className="container">
              <div className="row d-flex justify-content-between align-items-end">
                <div className="col-12 ">
                  <div className="blog-text-content text-center">
                    <p className="sub-heading">{subHeading}</p>
                    <h3 className="body-heading mb-3">{heading}<span className="color-red body-heading">{spanHeading}</span></h3>   
                    {content && <p className="body-paragraph">{content}</p>}
                  </div>
                </div>
                
              </div>
            </div>
          </div>
    
            <Swiper
                spaceBetween={50}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
                loop={true}
                autoplay={
                  {
                    delay:1500,
                    disableOnInteraction:false
                  }
                }
                modules={[Autoplay]}
                pagination={{ clickable: true }}
                observer ={true}
                observeParents={true}
                parallax={true}
                breakpoints={{
                    280: {
                      slidesPerView: 1,
                    },
                    992: {
                      slidesPerView: 3,
                    }
                  }}
                className="card-list py-4 px-3"
                id="articles-cards-slider"
                >
                {blogData.map((blogData,ind)=>{
                  return (<>
                    <SwiperSlide key={ind}>
                      <CardBox 
                        cardImg={blogData.cardImg} 
                        title={blogData.title} 
                        nextLineTitle={blogData.nextLineTitle}
                        content={blogData.content} 
                        anchor={blogData.anchor}
                      />
                    </SwiperSlide>
                  </>)
                })}
                
            </Swiper>
            
        </div>
      )
}

export default CardContent