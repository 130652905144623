import React from 'react'
import "./LatestNews.css"
import { useNavigate } from 'react-router-dom'

function LatestNews({firstBlog,redirectionFunction}) {
    const navigate = useNavigate()
  return (
    <div className="news_container standard-margin-space ">
        <div className='container' >
            <div className='row align-items-center'>
                <div className='col-md-6'>
                    <div className="news_img_container">
                        <img className="latest_news_img img-fluid" src={firstBlog.blog_image} />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="text_content">
                        <h3 className='h3_main mb-2'><span className='sub-heading color-red'>Latest News</span></h3>
                        <h2 className=' mb-2 body-heading' style={{fontSize:'30px'}}>{firstBlog.title}</h2>
                        <p className='para_main mb-3 body-paragraph'>{firstBlog.brief_paragraph}</p>
                        <a onClick={()=>{navigate(firstBlog.slug_url)}} className="read-more-anchor color-red">Read More <img src="/images/icons/carousel-btn-icon.svg" className="btn-icon" alt="btn-icon"/></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LatestNews