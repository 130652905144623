
const codes= {
codeWork :`
<h2 class="body-heading">Work Visa Application/Work Permit Extensions</h2>
<p class="body-paragraph">open work permits in Canada open many doors, and we are here to help you obtain and extend your work permit efficiently. Whether you are applying for a new work permit or extending your current one, we provide comprehensive support throughout the process.</p>

<h3 class="body-heading mt-4">Our Process</h3>
<div class="container">
  <ul class="custom-green-bullet-list row gy-2">
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Eligibility Check: Assess your eligibility for a work permit.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Document Collection: Collect all necessary documentation.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Application Submission: Submit your application to the immigration office.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Follow-Up: Track the status of your application and keep you informed.</p>
      </li>
    </div>
  </ul>
</div>

<p class="body-paragraph">We start by assessing your eligibility and then guide you through collecting the required documents. Once your application is prepared, we submit it and monitor its progress. Whether it's your first work permit or an extension, we make sure your transition is smooth and timely.</p>

<h3 class="body-heading mt-4">Perks of Choosing Excel World Immigration</h3>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Comprehensive Assistance: </p>
  <p class="body-paragraph">Get help with every step of the process.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Timely Updates: </p>
  <p class="body-paragraph">Stay informed about the status of your application.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Proven Success: </p>
  <p class="body-paragraph">Rely on our history of successful work permit applications.</p>
</div>
`,
codeVisitor:`
<h2 class="body-heading">Visitor Visa Application Canada</h2>
<p class="body-paragraph">Canada is a beautiful country to visit, and we make obtaining a Visitor Visa processing straightforward and stress-free. Whether you're visiting family, friends, or exploring Canada, we help you with the entire process.</p>

<h3 class="body-heading mt-4">Our Process</h3>
<div class="container">
  <ul class="custom-green-bullet-list row gy-2">
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Eligibility Assessment: Check your eligibility for a Visitor Visa.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Document Preparation: Gather and prepare all required documents.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Application Submission: Submit your application to the appropriate authorities.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Follow-Up and Updates: Monitor your application's progress and provide updates.</p>
      </li>
    </div>
  </ul>
</div>

<p class="body-paragraph">We assist you in determining your eligibility, gathering and preparing the necessary documents, and submitting your application. We also follow up on your application and keep you informed of any updates. Enjoy your visit to Canada with peace of mind.</p>

<h3 class="body-heading mt-4">Perks of Choosing Excel World Immigration</h3>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Simplified Process: </p>
  <p class="body-paragraph">Experience a smooth and hassle-free application process.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Expert Support: </p>
  <p class="body-paragraph">Benefit from our extensive knowledge of visitor visas.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Successful Outcomes: </p>
  <p class="body-paragraph">Trust in our high success rate for visitor visa applications.</p>
</div>
`,
codeStudy:`
<h2 class="body-heading">Student Visa Service in Canada</h2>
<p class="body-paragraph">Canada offers world-class education, and obtaining a Student Visa requirements for Canada is the first step toward achieving your academic goals. We provide comprehensive support to ensure your study permit application Canada is successful.</p>

<h3 class="body-heading mt-4">Our Process</h3>
<div class="container">
  <ul class="custom-green-bullet-list row gy-2">
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Eligibility Check: Assess your eligibility for a Student Visa.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Document Collection: Collect all necessary documentation.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Application Submission: Submit your application to the immigration authorities.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Follow-Up: Track the status of your application and keep you informed.</p>
      </li>
    </div>
  </ul>
</div>

<p class="body-paragraph">We begin by assessing your eligibility and guiding you through collecting the required documents. Once your application is prepared, we submit it and monitor its progress. Our goal is to make sure you can focus on your studies while we handle the paperwork.</p>

<h3 class="body-heading mt-4">Perks of Choosing Excel World Immigration</h3>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Educational Expertise: </p>
  <p class="body-paragraph">Leverage our experience with student visas and Canadian education.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Personalized Guidance: </p>
  <p class="body-paragraph">Receive tailored support for your specific academic needs.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">High Success Rate: </p>
  <p class="body-paragraph">Trust in our proven track record of successful student visa applications.</p>
</div>
`,
codeSuper:`
<h2 class="body-heading">Super Visa Consultants in Canada (Parental/Grandparents)</h2>
<p class="body-paragraph">The Super Visa Canada allows parents and grandparents to visit their family in Canada for extended periods. We help you navigate the application process smoothly and efficiently.</p>

<h3 class="body-heading mt-4">Our Process</h3>
<div class="container">
  <ul class="custom-green-bullet-list row gy-2">
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Eligibility Assessment: Determine your eligibility for a Super Visa.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Document Preparation: Gather and prepare all required documents.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Application Submission: Submit your application to the appropriate authorities.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Follow-Up and Updates: Monitor your application's progress and provide updates.</p>
      </li>
    </div>
  </ul>
</div>

<p class="body-paragraph">Our team will assist you in determining your eligibility, gathering and preparing necessary documents, and submitting your application. We also offer continuous support by following up on your application and providing regular updates, ensuring that you can enjoy your time with your family without any hassles.</p>

<h3 class="body-heading mt-4">Perks of Choosing Excel World Immigration</h3>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Family-Centered Service: </p>
  <p class="body-paragraph">Focus on reuniting families with our dedicated support.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Expert Assistance: </p>
  <p class="body-paragraph">Benefit from our deep understanding of the Super Visa process.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Reliable Outcomes: </p>
  <p class="body-paragraph">Trust in our successful track record for Super Visa applications.</p>
</div>
`,
codeCare:`
<h2 class="body-heading">Caregiver Immigration Canada Program (Home Support, Child Support)</h2>
<p class="body-paragraph">The Caregiver application Canada allows individuals to come to Canada to provide care for children, elderly persons, or those with medical needs. We help you through each step of the process.</p>

<h3 class="body-heading mt-4">Our Process</h3>
<div class="container">
  <ul class="custom-green-bullet-list row gy-2">
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Eligibility Check: Assess your eligibility for the Caregiver Program.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Document Collection: Collect all necessary documentation.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Application Submission: Submit your application to the immigration office.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Follow-Up: Track the status of your application and keep you informed.</p>
      </li>
    </div>
  </ul>
</div>

<p class="body-paragraph">We start by assessing your eligibility and then guide you through collecting the required documents. Once your application is prepared, we submit it and monitor its progress. Our goal is to ensure you can provide care and support in Canada as smoothly as possible.</p>

<h3 class="body-heading mt-4">Perks of Choosing Excel World Immigration</h3>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Specialized Knowledge: </p>
  <p class="body-paragraph">Utilize our expertise in caregiver immigration.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Comprehensive Support: </p>
  <p class="body-paragraph">Receive help with every step of your application.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Successful Placements: </p>
  <p class="body-paragraph">Trust in our history of successful caregiver applications.</p>
</div>
`,
codePnp:`
<h2 class="body-heading">PNP program in Canada</h2>
<p class="body-paragraph">The PNP application processing time allows provinces and territories to nominate individuals for permanent residence based on their skills and work experience. We provide expert assistance to help you navigate this process.</p>

<h3 class="body-heading mt-4">Our Process</h3>
<div class="container">
  <ul class="custom-green-bullet-list row gy-2">
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Eligibility Assessment: Determine your eligibility for a provincial nomination.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Document Preparation: Gather and prepare all required documents.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Application Submission: Submit your application to the provincial authorities.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Follow-Up and Updates: Monitor your application's progress and provide updates.</p>
      </li>
    </div>
  </ul>
</div>

<p class="body-paragraph">Our team helps you determine your eligibility, gather and prepare the necessary documents, and submit your application. We also follow up on your application and keep you informed of any updates. The PNP is a great pathway to Canadian permanent residence, and we're here to help you every step of the way.</p>

<h3 class="body-heading mt-4">Perks of Choosing Excel World Immigration</h3>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Provincial Expertise: </p>
  <p class="body-paragraph">Benefit from our knowledge of provincial nomination processes.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Tailored Guidance: </p>
  <p class="body-paragraph">Receive support specific to your chosen province.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">High Approval Rate: </p>
  <p class="body-paragraph">Trust in our successful track record for PNP applications.</p>
</div>
`,
codeRefused:`
<h2 class="body-heading">Visa Refusal/Refused Assistance Canada</h2>
<p class="body-paragraph">If you are facing visa refusal in Canada, don't worry – we can help. Our expertise in handling refused visa applications ensures that you have the best chance of success upon reapplication.</p>

<h3 class="body-heading mt-4">Our Process</h3>
<div class="container">
  <ul class="custom-green-bullet-list row gy-2">
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Review Refusal Reasons: Analyze the reasons for your visa refusal.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Document Preparation: Gather and prepare all required documents for reapplication.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Application Resubmission: Resubmit your application with additional information.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Follow-Up and Updates: Monitor your application's progress and provide updates.</p>
      </li>
    </div>
  </ul>
</div>

<p class="body-paragraph">We start by reviewing the reasons for your visa refusal and then help you gather and prepare additional documents for reapplication. Once your application is resubmitted, we monitor its progress and provide regular updates. Our goal is to turn your visa refusal into an approval.</p>

<h3 class="body-heading mt-4">Perks of Choosing Excel World Immigration</h3>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Refusal Expertise: </p>
  <p class="body-paragraph">Leverage our experience in handling visa refusals.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Comprehensive Analysis: </p>
  <p class="body-paragraph">Receive a thorough review of your refusal reasons.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">High Success Rate: </p>
  <p class="body-paragraph">Trust in our ability to turn refusals into approvals.</p>
</div>
`,
codeRefuge:`
<h2 class="body-heading">Refugee Cases in Canada</h2>
<p class="body-paragraph">Refugee application in Canada is a serious and often urgent matter. We provide compassionate and professional support to help you navigate the refugee application process.</p>

<h3 class="body-heading mt-4">Our Process</h3>
<div class="container">
  <ul class="custom-green-bullet-list row gy-2">
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Eligibility Assessment: Determine your eligibility for refugee status.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Document Preparation: Gather and prepare all required documents.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Application Submission: Submit your application to the appropriate authorities.</p>
      </li>
    </div>
    <div class="col-lg-12">
      <li class="px-2">
        <p class="body-paragraph mb-0">Follow-Up and Updates: Monitor your application's progress and provide updates.</p>
      </li>
    </div>
  </ul>
</div>

<p class="body-paragraph">Our team assists you in determining your eligibility for refugee status, gathering and preparing necessary documents, and submitting your application. We also offer continuous support by following up on your application and providing regular updates, ensuring that you can seek asylum in Canada with confidence.</p>

<h3 class="body-heading mt-4">Perks of Choosing Excel World Immigration</h3>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Compassionate Service: </p>
  <p class="body-paragraph">Experience empathetic support during difficult times.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Expert Guidance: </p>
  <p class="body-paragraph">Benefit from our extensive knowledge of refugee applications.</p>
</div>
<div class="d-flex align-items-start gap-2">
  <p class="step1-txt">Reliable Outcomes: </p>
  <p class="body-paragraph">Trust in our successful track record for refugee applications.</p>
</div>
`,
codeSpouse:`

<h2 class="body-heading">Spousal Sponsorship in Canada</h2>
<p class="body-paragraph ">Bringing your spouse to Canada via spousal sponsorship Canada is a significant step, and we are here to make it smooth and hassle-free. Our Canada Spousal Sponsorship service ensures that you and your spouse can reunite and start your new life together in Canada without unnecessary delays or complications.</p>
<h3 class="body-heading mt-4">Our Process</h3>
<div class="container">
<ul class="custom-green-bullet-list row gy-2">
<div class="col-lg-6"><li class=" px-2"><p class="body-paragraph mb-0">Federal Skilled Worker Program</p></li></div>
    <div class="col-lg-6"><li class="px-2"><p class="body-paragraph mb-0">Canadian Experience Class.</p></li></div>
    <div class="col-lg-6"><li class="px-2"><p class="body-paragraph mb-0">Federal Skilled Trades Program</p></li></div>
    </ul>
</div>
<h3 class="body-heading mt-4">The Express Entry System has Two Steps:</h3>
<div class="d-flex align-items-start gap-4"><p class="step1-txt">Eligibility Assessment: </p><p class="body-paragraph ">Determine your eligibility for sponsoring your spouse.</p></div>
<div class="d-flex align-items-start gap-4"><p class="step1-txt">Document Preparation: </p><p class="body-paragraph ">Gather and prepare all required documents.</p></div>
<div class="d-flex align-items-start gap-4"><p class="step1-txt">Application Submission: </p><p class="body-paragraph ">Submit your application to the appropriate immigration authorities.</p></div>
<div class="d-flex align-items-start gap-4"><p class="step1-txt">Follow-Up and Updates: </p><p class="body-paragraph ">Monitor your application's progress and provide updates.</p></div>

<p class="body-paragraph ">Our team will help you determine your eligibility, guide you in gathering and preparing necessary documents, and submit your application on your behalf. We also offer continuous support by following up on your application and providing regular updates. Reuniting with your loved one in Canada has never been easier.</p>

<h3 class="body-heading mt-4">Perks of Choosing Excel World Immigration</h3>
<div class="d-flex align-items-start gap-4"><p class="step1-txt">Expert Guidance: </p><p class="body-paragraph ">Benefit from our extensive knowledge and experience in immigration law.</p></div>
<div class="d-flex align-items-start gap-4"><p class="step1-txt">Personalized Support: </p><p class="body-paragraph ">Receive customized assistance tailored to your unique situation.</p></div>
<div class="d-flex align-items-start gap-4"><p class="step1-txt">Reliable Service: </p><p class="body-paragraph ">Trust our proven track record of successful sponsorship applications.</p></div>
`


}






export default  codes