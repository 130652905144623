import React from 'react'
import "./CheckList.css"

function CheckList({
    dataArr=[],
    row=4,
    content="center",
    anchorList=false
}) {
  return (
    <div className='CheckList-container container text-start'>
        <ul className={`row gy-4 custom-bullet-list justify-content-${content}`}>
            {anchorList?

                dataArr.map((ele)=>{
                    return(
                        <div class={`col-xl-${row} col-lg-6 ${content=="center" && "mx-auto"}`}><a href={ele.link} target='blank' style={{color:"black", textDecoration:"none"}}><li class="px-2"><p class="body-paragraph mb-0">{ele.content}</p></li></a></div>
                    )
                })
                :
                dataArr.map((ele)=>{
                    return(
                        <div class={`col-xl-${row} col-lg-6 ${content=="center" && "mx-auto"}`}><li class="px-2"><p class="body-paragraph mb-0">{ele}</p></li></div>
                    )
                })
            }
        </ul>
    </div>
  )
}

export default CheckList