import React,{useEffect} from 'react'
import "./TextContent.css"
import { useNavigate } from 'react-router-dom';
import DropDownContainer from './DropDownContainer';



function TextContent({
  heading,
  headingH1=false,
  spanHeading="",
  subHeading="",
  postSubHeading="",
  postHeading="",
  postContent="",
  content,
  btn=false,
  btnText,
  btnRef="/",
  shiftRow=false,
  img,
  reviewContent= false,
  review,
  reviewUser,
  userImg,
  mobileTextCenter=true,
  titleList=false,
  titleListContent=[],
  dropDown=false,
  dropDownList=[],
  btnScroll=false,
  bottomBoxText=false,
  visionText=false
}) {

  function ScrollToTopOnSamePage() {
    window.scrollTo(0, 0);
  }
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
  const navigate = useNavigate()
  return (
    <div className='text-content-section standard-padding-space'>
      <div className='container'>
        <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
          <div className={`col-lg-6 p-2 px-3 px-lg-4 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
            <img src={img} className='img-fluid' alt='content side' loading='lazy'/>
          </div>
          <div className='col-lg-6'>
            <div className={`text-content ${mobileTextCenter && "text-center text-lg-start"} `} style={{width:"95%"}}>
              <p className='sub-heading mb-0'>{subHeading}</p>
              {/* <div className='paddLeft'> */}
              <div className='paddLef'>
                {
                  headingH1 ?
                    <h1 className='body-heading my-2 mt-3 '>{heading} <br></br><span className='body-heading color-red'>{spanHeading}</span></h1>
                    :
                    <h3 className='body-heading my-2 mt-3 '>{heading} <br></br><span className='body-heading color-red'>{spanHeading}</span></h3>
                }
                <p className='sub-heading mb-3 mt-3 mb-xxl-4' style={{fontSize:"35px",fontWeight:"600"}}>{postSubHeading}</p>
                <div className=''>{paragraphs}</div>
                <h3 className='body-heading mt-3 my-xl-3 mt-xl-4'>{postHeading}</h3>
                <p className='body-paragraph my-1 mb-3'>{postContent}</p>
                {
                  titleList && 
                  <div>
                    {
                      titleListContent.map((ele)=>{
                        return(
                        <p className='body-paragraph'>
                          <span style={{fontSize:"18px",fontWeight:"600"}} className='color-red'>{ele.title}</span>{ele.content}
                        </p>)
                      })
                    }
                  </div>
                }
                {btn && <button className='genral-btn mt-4' onClick={()=>{btnScroll ? ScrollToTopOnSamePage() :navigate(btnRef)}}>{btnText}</button>}
              </div>
              {
                reviewContent &&
                <>
                  <div className='line-seprator my-2 mt-4'></div>
                  <div className='d-flex align-items-start gap-3 pt-4'>
                    <img src={userImg} alt='userImg' loading='lazy'/>
                    <div className='my-3'>
                      <p className='body-paragraph mb-1'>{review}</p>
                      <p className='body-paragraph mb-0'>{reviewUser}</p>
                    </div>
                  </div>
                  <button className='text-btn mt-3' onClick={()=>{btnScroll ? ScrollToTopOnSamePage() : navigate("/about")}}>ABOUT US</button>
                </>
              }
              {
                dropDown &&
                <>
                  {
                    dropDownList.map((ele,ind)=>{
                      return(
                        <>
                        <DropDownContainer
                          title={ele.title}
                          content={ele.content}
                        />
                        {ind < dropDownList.length-1 ? <div className='line-seprator'></div>:<></>}
                        </>
                      )
                    })
                  }
                </>
              }
              {
                bottomBoxText &&
                <div className='row gy-4'>
                  <div className='col-lg-6 px-2 '>
                    <div className='text-box p-4'>
                      <h3 className='body-heading'>15+</h3>
                      <p className='body-paragraph mb-0'>Years of experience</p>
                    </div>
                  </div>
                  <div className='col-lg-6 px-2'>
                    <div className='text-box p-4'>
                      <h3 className='body-heading'>1000+</h3>
                      <p className='body-paragraph mb-0'>Clients worldwide</p>
                    </div>
                  </div>
                </div>
              }
              {
                visionText&&
                <div className='row gy-4'>
                  <div className='col-12'>
                    <div className='vission-text d-flex gap-2 align-items-start justify-content-start'>
                        <div className='vision-img-box'>
                          <img className='' src='/images/icons/vision-icon.svg' loading='lazy'/>
                        </div>
                        <div>
                          <h6 className='sub-heading'>VISION</h6>
                          <p className='mb-0 body-paragraph'>Our vision is to be the leading immigration consultancy, known for our dedication, reliability, and success in helping clients achieve their Canadian dream.</p>
                        </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='vission-text d-flex gap-2 align-items-start justify-content-start'>
                      <div className='vision-img-box'>
                        <img className=' ' src='/images/icons/mission-icon.svg' loading='lazy'/>
                      </div>
                        <div>
                          <h6 className='sub-heading'>MISSION</h6>
                          <p className='mb-0 body-paragraph'>Our mission is to provide expert guidance and personalized support to individuals and families seeking to make Canada their new home.</p>
                        </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextContent