import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/FooterNew/Footer';
import Helmet from "react-helmet"

function ThankYou() {
    let heading = "Thank you for submitting your request for contact";
    let message = "We've received it and will be in touch with more details soon.";

    
  return (
    <>
    <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
        <HeroBanner
            Heading={heading}
            subHeading=""
            spanHeading=""
            Paragraph={message}
            btnText=""
            bgImg="/images/creatives/plane-hero-light-bg.png"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={false}
        />
        <Footer/>
    </>
  )
}

export default ThankYou