import React,{useState,useEffect,useRef} from 'react'
import "./AssessmentForm.css"
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


function AssessmentForm() {
    const [name,setName]= useState()
    const [email,setEmail]= useState()
    const [phone,setPhone]= useState()
    const [dob , setDob] = useState("")
    const [profession,setProfession]= useState("")
    const [details,setDetails]= useState("")
    
    
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()


    const numericInputsRef = useRef([]);

    useEffect(() => {
        const handleWheel = (e) => {
          e.preventDefault();
        };
      
        if (numericInputsRef && numericInputsRef.current) {
          const numericInputs = numericInputsRef.current;
          numericInputs.forEach((numericInput) => {
            numericInput.addEventListener('wheel', handleWheel, { passive: false });
          });
      
          // Cleanup the event listeners when the component unmounts
          return () => {
            numericInputs.forEach((numericInput) => {
              // Check if the element is still in the DOM before removing the event listener
              if (numericInput && numericInput.removeEventListener) {
                numericInput.removeEventListener('wheel', handleWheel);
              }
            });
          };
        }
      
        // Return an empty cleanup function if numericInputsRef is not available
        return () => {};
      }, [numericInputsRef]);
      
      

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
          .post("/send-assesment", {
            email: email,
            formType: "Assesment",
            assementData:{
                name:name,
                email:email,
                phone:phone,
                dateOfBirth:dob,
                profession:profession,
                details:details,
            }
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Assesment Submited Successfully!");
              setLoading(false);
              setEmail("");
            //   window.open("/thank-you", '_blank');
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Failed to send Assesment, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };

   
      const handlePhoneChange = (e) => {
        const numericValue = e.target.value.replace(/\D/g, '');
        const maxLength = 20; 
        if (numericValue.length <= maxLength) {
          setPhone(numericValue);
        }
      };
  return (
    <div className='assessment-form-container standard-padding-space'>
        <div className='container'>
            <h3 className='body-heading my-4 text-center'>About <span className='color-red'>Yourself</span></h3>

            <form className='form-container py-3' onSubmit={formSubmitHandler}>
                <div className='row my-4 gy-3'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6 col-md-6'>
                        
                        
                        <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            maxLength="36"
                            required
                            type="text"
                            placeholder='Full Name'
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6 col-md-6'>
                        
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            maxLength="100"
                            required
                            type='email' 
                            placeholder='Email Address' 
                        />
                    </div>
                   
                </div>    
                <div className='row my-4 gy-3'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                        
                    <input
                        value={dob}
                        name="dob"
                        onChange={(e) => setDob(e.target.value)}
                        required
                        type="text"
                        placeholder="Date Of Birth (MM-DD-YYYY)"
                        className=""
                        />
                           
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                                
                                <input
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    min="15" max="20"
                                    required
                                    type='tel' 
                                    placeholder='Phone Number'

                                />
                            </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-4 col-md-6'>
                        
                        <input
                            value={profession}
                            onChange={(e) => setProfession(e.target.value)}
                            maxLength="50"
                            required
                            type="text"
                            placeholder='Your Profession'
                        />
                    </div>
                </div>    
                <div className='row my-3'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                        
                        <textarea
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                            maxLength="436"
                            rows={5}
                            required
                            type="text"
                            placeholder='Submit Your Other Details'
                        />
                    </div>
                </div>    
                  
                

                <div className='row pt-5'>
                    <div className='col-lg-12'>
                        <button className='genral-btn w-100 d-flex justify-content-center align-items-center' type='button'>
                            Submit Now
                            {loading ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "white",marginLeft:"10px" }}
                                />
                            ) : (
                            success ? (
                                <div>
                                {setLoading(false)}
                                {setSuccess(false)}
                                </div>
                            ) : (
                                error && (
                                <div>
                                    {setLoading(false)}
                                    {alert(error)}
                                    {setError(false)}
                                </div>
                                )
                            )
                            )}
                        </button>
                    </div>
                </div>  
            </form>
        </div>
    </div>
  )
}

export default AssessmentForm