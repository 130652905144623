import React from 'react'
import CheckList from '../CheckList/CheckList'
import "./CitizenshipText.css"
 


const dataarr1 = [
    "Be a Permanent Resident.",
    "Show how well you know Canada ( Must pass the Citizenship Test and Interview )", 
    "Have lived in Canada for 3 out of the last 5 years",
    "Prove your language skills",
    "Have filed your taxes, if you need to"
 ]
 
 const dataArr2 = [
     " If you are in prison, on parole or on probation in Canada, or are serving a sentence outside Canada,",
     " If you have been convicted of an indictable offence in Canada or an offence outside Canada in the four years before applying for citizenship, or",
     "If you are charged with, on trial for, or involved in an appeal of an indictable offence in Canada, or an offence outside Canada",
 ]

function CitizenshipText() {
    return (
        <div className='CitizenshipText-container standard-padding-space'>
            <div className='container'>
                <div className='text-content text-center'>
                    <h3 className='body-heading mb-4'>Who Can Apply For <span className='color-red body-heading'>Canada Citizenship</span></h3>
    
                    <div className='text-center my-3'>
    
                    
                    
                    <p className='body-paragraph mb-4'>Applying for Canadian Citizenship is a very important step. Due diligence is required while preparing the application and following the correct procedure. To be eligible to become a Canadian citizen, you must:</p>
    
                    <div className='box'>
                        <CheckList
                            dataArr={dataarr1}
                            row={12}
                            content='start'
                        />
    
                    </div>
                    <h3 className='body-heading my-4 mt-5'>Residency Requirments: <span className='color-red body-heading'>Time You Have Lived In Canada</span></h3>
                    <p className='body-paragraph my-4'>Adults and some minors must have been physically present in Canada for at least 1095 days during the five years right before the date you sign your application.</p>
                    <p className='body-paragraph my-4'> We encourage you to apply with more than 1095 days of physical presence to have extra days in case there is a problem with the calculation. </p>
                    <p className='body-paragraph my-4'>You must also be physically present for at least 183 days during each of four calendar years that are fully or partially within the six years immediately before the date of application. These requirements do not apply to children under 18. </p>
                    <p className='body-paragraph my-4'>You do not need to have a PR card to apply for citizenship. If you have a PR card, but it is expired, you can still apply for citizenship.</p>
                    <div className='box text-start'>
                        <h5 className='sub-heading'>Criminal Prohibitions</h5>
                        <p className='body-paragraph'>If you have committed a crime in or outside Canada, you may not be eligible to become a Canadian citizen for a period of time. For example,</p>
                        <CheckList
                            dataArr={dataArr2}
                            row={12}
                            content='start'
                        />

                        <p className='body-paragraph mb-0'>Time in prison or on parole does not count as the time you have lived in Canada. Time on probation also does not count if you were convicted of a crime.</p>
    
                    </div>
                    <h3 className='body-heading my-4 mt-5'>Passing The <span className='color-red body-heading'>Canada Citizenship Test And Interview</span></h3>
                    <p className='body-paragraph'>If you are 14 to 64 years of age, when you apply for citizenship, you will need to take a citizenship test to show you have adequate knowledge of Canada and the responsibilities and privileges of citizenship. It is usually a written test, but it is sometimes taken orally with a citizenship officer. </p>
                    <p className='body-paragraph'>All you need to know for the test is in IRCC free study guide, Discover Canada: The Rights and Responsibilities of Citizenship. You will be sent a copy of it once your application is filed. The questions in the citizenship test are based on this study guide</p>
                   
                    </div>
                </div>
    
            </div>
        </div>
      )
}

export default CitizenshipText