import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/FooterNew/Footer'

function NotFound() {
  return (
    <>
   
        <HeroBanner
            subHeading=""
            Heading="404 Not Found"
            nextLineSpanHeading=""
            Paragraph=""
            btnText=""
            bgImg={"/images/creatives/plane-hero-bg.png"}
            btn={false}
            btnRef="/"
            bgEffect={true}
            isCheveron={false}
        />
        <Footer/>
        
    </>
  )
}

export default NotFound