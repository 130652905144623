import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import NewsLetter from '../component/NewsLetter/NewsLetter'
import FooterNew from "../component/FooterNew/Footer"
import { Helmet } from 'react-helmet'

function Contact() {
    return (
      <div className="home-pag">
       <Helmet>
          <title> 
          Need Immigration Help? Let's Discuss Your Project!
          </title>
          <meta 
          name='description' 
          content="Let's talk about your immigration needs! Contact us to discuss your project and find the best solutions for your immigration goals."
          />
          
        </Helmet>
        <HeroBanner 
          subHeading=""
          Heading="Need help with a Immigration? "
          spanHeading='Let’s talk!'
          nextLineSpanHeading=""
          Paragraph="No matter what Immigration service you require, we will provide transparent and reliable experience with our expert knowledge and personal touch"
          btnText=""
          bgImg="/images/creatives/contact-hero-banner.jpg"
          btn={false}
          btnRef="/"
          bgEffect={true}
          socialLinks={true}
          serviceForm={true}
          isCheveron={false}
        />
              

        <FooterNew/>
  </div>
    )
  }
  
  export default Contact