import React from 'react'
import "./SimpleTextCard.css"

function SimpleTextCard({dataArr=[]}) {
  return (
    <div className='SimpleTextCard-container py-4'>
        <div className='container '>
            <div className='row gy-3'>
                {
                    dataArr.map((ele)=>{
                        return(
                            <div className='col-xl-3'>
                                <div className='simple-card-box text-center'>
                                    <h3 className='body-heading' style={{fontSize:'20px'}}>{ele.title}</h3>
                                    <p className='body-paragraph mt-2' style={{lineHeight:"1.4"}}>{ele.content}</p>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    </div>

  )
}

export default SimpleTextCard