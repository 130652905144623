import "./Testimonials.css"
import React from "react";
import TestimonialCard from "./TestimonialCard";
import { Autoplay,Pagination  } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


function Testimonials() {
  const blogData = [
    {
      clientName: "John Smith",
      clientReview:"Excel World Immigration made the process of moving to Canada so easy. Their team was always there to answer my questions and guide me through every step.",
      clientImg:"/images/creatives/testimonial-img-male.png"
    },
    {
      clientName: "Maria Garcia",
      clientReview:"Thanks to Excel World Immigration, I was able to reunite with my family in Canada. They handled everything quickly and professionally.",
      clientImg:"/images/creatives/testimonial-img.png"
    },
    {
      clientName: "David Brown",
      clientReview:"I never thought immigrating to Canada would be so smooth. The team at Excel World Immigration is knowledgeable and incredibly supportive.",
      clientImg:"/images/creatives/testimonial-img-male.png"
    },
    {
      clientName: "Linda Johnson",
      clientReview:"From the first call to getting my visa, Excel World Immigration was fantastic. They made the whole process stress-free and straightforward.",
      clientImg:"/images/creatives/testimonial-img.png"
    },
    {
      clientName: "Michael Lee",
      clientReview:"Choosing Excel World Immigration was the best decision I made. Their expertise and dedication helped me achieve my dream of studying in Canada.",
      clientImg:"/images/creatives/testimonial-img-male.png"
    },
    
  ];

  return (
    <div className="Testimonials-container standard-padding-space">
      <div className="w-100 container px-0 my-3">
    <div className=" d-flex align-items-center w-100">
      <div className="col-12 text-center px-0">
        <p className="sub-heading mb-2">Our Testimonials</p>
        <h3 className="body-heading">What Our <span className="body-heading color-red">Client Say About Us</span></h3>
        <p className="body-paragraph">We pride ourselves on providing top-notch service and support to our clients. Here's what some of them have to say about their experience with Excel World Immigration:</p>

        <div className="px-3 pt-3">
            <div className="d ">
            <Swiper
              spaceBetween={50}
              pagination={true}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              loop={true}
              autoplay={
                {
                  delay:2000,
                  disableOnInteraction:false
                }
              }
              modules={[Autoplay,Pagination]}
              observer ={true}
              observeParents={true}
              parallax={true}
              breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 3,
                }
              }} 
              className="testimonials-list py-5"
              id="testimonial-cards-slider"
                  >
                    {
                      blogData.map((clientData,ind)=>{
                        return(<>
                          <SwiperSlide key={ind} >
                            <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview}/>
                          </SwiperSlide>
                          </>)
                      })
                    }
              </Swiper>
              {/* <div className="d-flex align-items-center justify-content-start mt-3">
                <a 
                  href="https://www.google.com/maps/place/HQ+Network+Solutions+Inc./@43.6598286,-79.6010181,17z/data=!4m8!3m7!1s0x882b3f2a275d1e1b:0xc04801cd06c594b8!8m2!3d43.6598286!4d-79.6010181!9m1!1b1!16s%2Fg%2F11dx9wxvdl?entry=ttu" 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="genral-btn">read more</button>
                </a>
              </div> */}
          </div>
        </div>
        
       
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials