import React from 'react'
import "./NewsCard.css"

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

function NewsCard(props) {
  const title = truncateText(props.title,50)
  return (
    <div className="news_card pb-3 h-100 " >
      <img src={props.img} className="card_img img-fluid" alt="blog thumbnail" />
      <div className="card_text d-flex flex-column align-items-start justify-content-between">
        
        <h4 className={`h4_main my-auto`}>
          <span className="card_title body-heading">{title}</span>
        </h4>
        <a onClick={()=>{props.redirectionFunction(props.anchor)}} className="read-more-anchor color-red">Read More <img src="/images/icons/carousel-btn-icon.svg" className="btn-icon" alt="btn-icon"/></a>
      </div>
    </div>
  )
}

export default NewsCard