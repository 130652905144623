import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import NewsList from '../component/NewsList/NewsList'
import FooterNew from "../component/FooterNew/Footer"

function News() {
  return (
    <div>
        <HeroBanner 
            subHeading=""
            Heading="News & Updates"
            nextLineSpanHeading=""
            Paragraph="Stay informed with the latest immigration news and updates to navigate your Canadian journey effectively."
            btnText=""
            bgImg="/images/creatives/news-hero-bg.jpg"
            btn={false}
            btnRef="/"
            bgEffect={false}
            isCheveron={false}
        />
        <div className=' elevate'>
            <div className='left-gradient'>

            </div>
            <NewsList/>
        </div>

        <FooterNew/>
    </div>
  )
}

export default News