import React from 'react'
import "./BlogDetails.css"
import PopularBlogCard from "./PopularBlogCard"
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import NewsCard from '../NewsList/NewsCard';
const popularBlogDate = [
    {img:'/images/creatives/blog-thumb-1.png', title:"Lorem Ipsum is simply dummy text lorem",date:"Apr 10, 2023",anchor:"/newsinner"},
    {img:'/images/creatives/blog-thumb-2.png', title:"Lorem Ipsum is simply dummy text lorem",date:"Apr 10, 2023",anchor:"/newsinner"},
    {img:'/images/creatives/blog-thumb-3.png', title:"Lorem Ipsum is simply dummy text lorem",date:"Apr 10, 2023",anchor:"/newsinner"},
]

function BlogDetails({ text, blogsData, datePublished,recentBlogs }) {
    const navigate= useNavigate()


    const convertToSlug = (str) => {
        return str.toLowerCase().replace(/\s+/g, "-");
      };

    const redirectUserToBlog = (id) => {
        if (!id) return;
        let slug = convertToSlug(id);
    
        navigate(`/news/${slug}`);
      };


  return (
    <div className='blog-details-container pb-5'>
        <div className='container py-5 '>
            <div className='row d-flex flex-row align-items-start '>
                
            <div className='col-12'>
            <div className='text-content'>
                
                <p className='body-paragraph blog-content'>{text}</p>
                {blogsData.map((item) => (
                <>
                    <h2 className="content-title my-3 mt-4" style={{textTransform:"uppercase",color:"#006EAE"}}>{item.heading}</h2>
                    <div className="body-paragraph" dangerouslySetInnerHTML={{ __html: item.paragraph }}></div>
                </>
                ))}
               
           </div>
          
           <div className='line-seprator mt-4'></div>
        </div>
        <div className='col-12'>
                    <h3 className='body-heading text-center my-4 mt-5'>Latest News & Updates</h3>

                    <div className='popular-blog-container p-3 px-4'>
                    <Swiper
                        spaceBetween={30}
                        navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                        }}
                        loop={true}
                        autoplay={
                        {
                            delay:1500,
                            disableOnInteraction:false
                        }
                        }
                        modules={[Autoplay]}
                        pagination={{ clickable: true }}
                        observer ={true}
                        observeParents={true}
                        parallax={true}
                        breakpoints={{
                            280: {
                            slidesPerView: 1,
                            },
                            500:{
                            slidesPerView: 2,
                            },
                            992: {
                            slidesPerView: 3,
                            }
                        }}
                        className="card-list py-4 px-3"
                        >
                        {
                            recentBlogs.map((blog,ind)=>{
                                return(
                                    <SwiperSlide>
                                        <NewsCard
                                            img={blog.blog_creative}
                                            title={blog.main_heading}
                                            date={blog.date}
                                            anchor={blog.slug_url}
                                            redirectionFunction={redirectUserToBlog}
                                        />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    </div>
                    
                    
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default BlogDetails