import React from 'react'
import ImmigrationServicesCard from './ImmigrationServicesCard'
import "./ImmigrationServices.css"

function ImmigrationServices({
    dataArr
}) {
  return (
    <div className='ImmigrationServices-container standard-padding-space'>
        <div className='container my-4'>
            <div className='row gy-4'>
                {
                    dataArr.map((ele)=>{
                        return(
                            <div className='col-lg-4'>
                                <ImmigrationServicesCard
                                    cardImg={ele.cardImg}
                                    title={ele.title}
                                    content={ele.content}
                                    anchor={ele.anchor}
                                />
                            </div>
                        )
                    })
                }
                
            </div>
        </div>
    </div>
  )
}

export default ImmigrationServices