
function CardBox(props) {
    return (
      <div className="card-box">
        <div>
          {
            props.cardImg ?
              <>
              {
                props.anchor ?
                <a href={props.anchor} target="blank">
                    <img src={props.cardImg} alt={props.title} />     
                </a>
                :
                <img src={props.cardImg} alt={props.title} />
              }
              </>
              :
              <div style={{height:"305px",width:"100%"}}>

              </div>

          }
          <div className="card-text-content">
            <h2>{props.title}
            {
              props.nextLineTitle &&
              <>
                <br></br>
                {props.nextLineTitle}
              </>
            }

            </h2>
            
            <p>{props.content}</p>
          </div>
        </div>
          
      </div>
    )
}

export default CardBox