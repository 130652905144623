import React from 'react'
import "./ECardSection.css"
import { Navigate, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function ECardSection({
    image=null,
    name,
    rank
    }) {
    const navigate = useNavigate()
  return (
    <>
    
    <div className='ECardSection-container py-4'>
        <div className='container bg-yellow px-4 px-lg-5 py-5'>
            <div className='d-flex flex-column-reverse flex-lg-row align-items-center gap-5 '>
                <div className='left-text-section h-100'>
                    <h3 className='body-heading font-size-small' style={{color:"white"}}>{name}</h3>
                    <h3 className='body-heading font-size-small' style={{color:"white"}}>{rank}</h3>
                    <div className='mt-5'>
                    <ul className="d-flex flex-wrap social-icons-container align-items-center justify-content-center justify-content-lg-start align-items-lg-start gap-4 p-0 py-4 py-lg-5">
                        <li><a target="blank" href="https://www.instagram.com/excelimmigration/">
                        <img src="images/icons/insta-icon-w.png" alt="instagram logo" />
                        </a>
                        </li>
                        <li><a target="blank" href="https://x.com/ExcelWorldImmi">
                        <img src="images/icons/twitter-icon-w.png" alt="twitter logo" /></a></li>
                        <li><a target="blank" href="https://www.facebook.com/profile.php?id=100084682533065">
                        <img src="images/icons/facebook-icon-w.png" alt="facebook logo" /></a></li>
                        <li><a target="blank" href="https://www.linkedin.com/company/excel-world-immigration-and-education/about/">
                        <img src="images/icons/linked-icon-w.png" alt="linked in logo" /></a></li>
                        <li><a target="blank" href="https://www.google.com/maps/place/Excel+World+Immigration+and+Education+Consultant+Inc/@43.6929733,-79.697302,17z/data=!4m8!3m7!1s0x882b3f88ec487b5d:0xb1a49d3b82ea67e1!8m2!3d43.6929733!4d-79.697302!9m1!1b1!16s%2Fg%2F11h5md9dks?entry=ttu">
                        <img src="images/icons/google-icon-w.png" alt="google logo" /></a></li>
                    </ul>
                    </div>
                </div>

                {
                    image &&
                        <div className='text-center text-lg-end '>
                            <img src={image} alt='person picture' className='main-img img-fluid' />
                        </div>
                }
                
            </div>

            <div className='text-center'>
                <div className='d-flex flex-column flex-lg-row align-items-center gap-3 w-100 justify-content-evenly pt-5'>
                    <a href='tel:437 422 0422' className='card-anchor'>
                        <div className='card-box d-flex flex-column' >
                            <img src='/images/icons/ecard-call-icon.png' className='ecard-icon' alt="call"/>
                            <h3 className='body-heading' style={{color:"white"}}>Call</h3>
                        </div>
                    </a>
                    <a href='mailto:excelworldimmigration@gmail.com' className='card-anchor'>
                        <div className='card-box d-flex flex-column' >
                            <img src='/images/icons/ecard-mail-icon.png' className='ecard-icon' alt="call"/>
                            <h3 className='body-heading' style={{color:"white"}}>Mail</h3>
                        </div>
                    </a>
                    <a href='sms:437 422 0422' className='card-anchor'>
                        <div className='card-box d-flex flex-column' >
                            <img src='/images/icons/ecard-sms-icon.png' className='ecard-icon' alt="call"/>
                            <h3 className='body-heading' style={{color:"white"}}>SMS</h3>
                        </div>
                    </a>
                    <a href='https://api.whatsapp.com/send?phone=14374220422' target='blank'  className='card-anchor'>
                    {/* <a href='https://wa.me/14374220422' target='blank' className='card-anchor'> */}
                    {/* <a href='whatsapp://send?phone=+14374220422' target='blank' className='card-anchor'> */}
                        <div className='card-box d-flex flex-column' >
                            <img src='/images/icons/ecard-wa-icon.png' className='ecard-icon' alt="call"/>
                            <h3 className='body-heading' style={{color:"white"}}>WhatsApp</h3>
                        </div>
                    </a>
                </div>

                <button className='genral-btn mt-4' onClick={()=>{navigate("/contact")}}>Get in Touch</button>

            </div>

        </div>

    </div>
    </>
  )
}

export default ECardSection