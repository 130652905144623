import React from 'react'
import "./Sponsor.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function Sponsor({offOnSlide}) {
  return (
    <div className='sponsor-container  standard-padding-space'>
      <div className='container my-4'>
      <div className='py-2 '>
        <div className='text-content text-center'>
          <h3 className='body-heading'>Awards <span className='color-red body-heading'>& Certifications</span></h3>
          <p className='body-paragraph'>We take pride in our achievements and the recognition we've received for our dedication to excellence. Our awards and certifications reflect our commitment to providing top-quality immigration services.</p>
        </div>
      </div>
        <div className="companies-logo-box mt-4">
        <Swiper
            spaceBetween={10}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1400: {
                  slidesPerView: 5,
                },
              }}
            className={`mx-auto ${offOnSlide && "hideOnNav"}`}
            >
                {/* <SwiperSlide>
                <img src="/images/icons/sponsor-logo-1.png" alt="sponsor icon" />
                </SwiperSlide> */}
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-2.png" alt="sponsor icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-3.png" alt="sponsor icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-4.png" alt="sponsor icon" />
                </SwiperSlide>
                {/* <SwiperSlide>
                <img src="/images/icons/sponsor-logo-5.png" alt="sponsor icon" />
                </SwiperSlide>

                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-1.png" alt="sponsor icon" />
                </SwiperSlide> */}
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-2.png" alt="sponsor icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-3.png" alt="sponsor icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-4.png" alt="sponsor icon" />
                </SwiperSlide>
                {/* <SwiperSlide>
                <img src="/images/icons/sponsor-logo-5.png" alt="sponsor icon" />
                </SwiperSlide> */}
                  
            </Swiper>
        </div>
        <div className='py-2'></div>
        </div>
    </div>
  )
}

export default Sponsor

