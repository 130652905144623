import React, { Fragment, useEffect, useState } from "react";
import HeroBanner from "../component/Common Banner Component/HeroBanner"
import Footer from '../component/FooterNew/Footer'
import { useParams } from "react-router";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import BlogDetails from "../component/BlogDetails/BlogDetails";

function BlogInner() {

  const [blogsData, setBlogsData] = useState();
  const [recentBlogs, setRecentBlogs] = useState();
  
  const [loading, setLoading] = useState(true);
  
  
  const { id } = useParams();

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const getAllBlogs = async (slug) => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_recent_blogs = [];
          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_creative: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
              main_heading: item.title,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          let blog = res.data.data.find((item) => item.slug_url == slug);
          setBlogsData(blog);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    
      getAllBlogs(id);
  },[id]);


  return (
    <>
    {loading  ? (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ color: "#EF5717", width: "120px", height: "120px" }}
            />
          </div>
        ) : (
          <>
            
              <HeroBanner
                subHeading=""
                Heading={blogsData.title}
                spanHeading=""
                Paragraph={``}
                blogInnerPara={{catagory:"Immigration",author:"By Excel World",date:`${blogsData.published_date}`}}
                darkBg={true}
                btnText=""
                bgImg="/images/creatives/inner-news-bg.jpg"
                btn={false}
                statSection={false}
                socialLinks={false}
                serviceForm={false}
                sideImage={false}
              />
              {recentBlogs && <BlogDetails
                blogsData={blogsData.blogs_content}
                datePublished={blogsData.published_date}
                text={blogsData.brief_paragraph}
                recentBlogs={recentBlogs}
                
              />}
            <Footer/>
          </>
        )}
    </>
  )
}

export default BlogInner