import React,{useState,useEffect} from "react";
import "./CardBlog.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
// import CardBox from "./CardBox";
import CardBox from "../ServicesCarousel/ServicesBox";
import axios from "axios"
import {useNavigate} from "react-router-dom"
import {Spinner} from "react-bootstrap"

const dumyBlogs = [
  {
    blog_creative: "/images/creatives/blog-thumb-1.png",
    main_heading: "Convallis vivamus at cras porta ibh elit aliquam",
    paragraph:"Convallis vivamus at cras porta nibh velit aliquam eget in faucibus",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-1.png",
    main_heading: "Convallis vivamus at cras porta ibh elit aliquam",
    paragraph:"Convallis vivamus at cras porta nibh velit aliquam eget in faucibus",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-1.png",
    main_heading: "Convallis vivamus at cras porta ibh elit aliquam",
    paragraph:"Convallis vivamus at cras porta nibh velit aliquam eget in faucibus",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
  },
  
];

let defaultBlogObj = []

function CardTestimonial({
  subHeading,
  heading,
  content,
  spanHeading,
  showBlogsSection
}) {

  const [blogs, setBlogs] = useState();
  const [recentBlogs, setRecentBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let blogAPICalled = false;

  useEffect(() => {
    getAllBlogsListing();
  }, []);

  const getAllBlogsListing = () => {
    if (blogAPICalled) return;
    blogAPICalled = true;

    setLoading(true);
    axios
      .get("/blogs", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data_Blogs = [];
          let Updated_recent_blogs = [];
          res.data.data.forEach((item) => {
            Updated_data_Blogs.push({
              id: item.blog_id,
              main_heading: item.title,
              paragraph: item.brief_paragraph,
              logo: "/favicon.png",
              entity: "Creative Squad",
              date: item.published_date,
              slug_url: item.slug_url,
              category: item.category,
              blog_creative: item.blog_image,
            });
          });
          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          setBlogs(()=> Updated_data_Blogs);
          showBlogsSection(Updated_data_Blogs && Updated_data_Blogs.length !== 0 ? true:false)

          if(blogs.length <= 3)
          {
            setBlogs((prev)=>[...prev,...prev])
          }
          defaultBlogObj = Updated_data_Blogs;
          setLoading(false);
        }
          
      })
      .catch((err) => {
        console.log("eror =>",err);
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/news/${slug}`);
  };

  return (
    <div className='blog-container  standard-padding-space my-4 px-1'>
      <div className="container">
      <div className="blog-upper-content mb-5">
        <div className="containe">
            <div className="blog-text-content text-center">
              <p className="sub-heading mb-1">{subHeading}</p>
              <h3 className="body-heading mb-0">{heading}<span className="color-yellow">{spanHeading}</span></h3>   
              {content && <p className="body-paragraph my-3 px-2 px-md-0">{content}</p>}
            </div>
        </div>
      </div>
      {loading ? 
        (<div className="w-100 text-center mb-5">
          <Spinner
            style={{ color: "#e9511a", width: "120px", height: "120px" }}
          />
        </div>):(
        <Swiper
            spaceBetween={30}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            loop={true}
            autoplay={
              {
                delay:1500,
                disableOnInteraction:false
              }
            }
            modules={[Autoplay]}
            pagination={{ clickable: true }}
            observer ={true}
            observeParents={true}
            parallax={true}
            breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                500:{
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 2,
                }
              }}
            className="card-list py-4 px-3"
            id="articles-cards-slider"
            >
            {blogs &&
            blogs.map((blogData,ind)=>{
              return (<>
                <SwiperSlide key={ind}>
                <CardBox cardImg={blogData.blog_creative} title={blogData.main_heading} content={blogData.paragraph} anchor={blogData.slug_url} redirectionFunction={redirectUserToBlog}/>
                 
                </SwiperSlide>
              </>)
            })}
            
        </Swiper>)}
        </div>
    </div>
  )
}

export default CardTestimonial