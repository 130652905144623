import "./ServicesCarousel.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "./ServicesBox";

const handlePrevButtonClick = () => {
  const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
  swiperInstance.slidePrev();
};

const handleNextButtonClick = () => {
  const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
  swiperInstance.slideNext();
};

function ServicesCarousel({
  subHeading,
  heading,
  content,
  spanHeading
}) {

  const blogData = [
    {
      cardImg: "./images/creatives/service-card-1.png",
      title: "Express Entry",
      anchor:"/express-entry",
      content:
        "Fast-track your way to becoming a permanent resident in Canada through a point-based system.",
    },
    {
      cardImg: "./images/creatives/service-card-2.png",
      title: "Provinvial Nominee Program",
      anchor:"/immigration/pnp-program",
      content:
        "Move to a specific province in Canada and enjoy all it has to offer with the PNP.",
    },
    {
      cardImg: "./images/creatives/service-card-4.png",
      title: "Refugee Cases",
      anchor:"/immigration/refugee-cases",
      content:
        "Seeking asylum in Canada is a serious and often urgent matter and we provide the right assistance for it!",
    },
    {
      cardImg: "./images/creatives/service-card-5.png",
      title: "Refused Visa",
      anchor:"/immigration/refused-visa",
      content:
        "If your Visa Application has been refused, don't worry - we can help!",
    },
    {
      cardImg: "./images/creatives/service-card-6.png",
      title: "Super Visa",
      anchor:"/immigration/super-visa",
      content:
        "The Super Visa allows parents & grandparents to visit their family in Canada for extended periods.",
    },
    {
      cardImg: "./images/creatives/immigration-card-7.png",
      title: "Sponsoring Spouse",
      anchor:"/immigration/sponsoring-spouse",
      content:
        "Reunite with your spouse in Canada through our efficient and supportive sponsorship services.",
    },
    {
      cardImg: "./images/creatives/immigration-card-8.png",
      title: "Work Permit",
      anchor:"/immigration/work-permit",
      content:
        "Obtain a work permit and gain valuable Canadian work experience under our guidance and support.",
    },
    {
      cardImg: "./images/creatives/immigration-card-9.png",
      title: "Visitors Visa",
      anchor:"/immigration/visitors-visa",
      content:
        "Visit Canada for leisure, business, or family visits, facilitated by our streamlined visa application process.",
    },
    {
      cardImg: "./images/creatives/immigration-card-10.png",
      title: "Live-in Caregivers",
      anchor:"/immigration/live-in-caregivers",
      content:
        "Care for families in Canada while living with them, facilitated by our compassionate and experienced immigration assistance.",
    },
    {
      cardImg: "./images/creatives/immigration-card-11.png",
      title: "Student Visa",
      anchor:"/immigration/student-visa",
      content:
        "Pursue your education goals in Canada with ease, supported by our knowledgeable team specializing in student visas.",
    },
    
  ];

  return (
    <div className='ServicesCarousel-container standard-padding-space container my-4'>
      <div className="blog-upper-content mb-4">
        <div className="container">
          <div className="row d-flex justify-content-between align-items-end">
            <div className="col-12 ">
              <div className="blog-text-content text-center">
                <p className="sub-heading">{subHeading}</p>
                <h3 className="body-heading mb-3">{heading}<span className="color-red body-heading">{spanHeading}</span></h3>   
                {content && <p className="body-paragraph">{content}</p>}
              </div>
            </div>
            
          </div>
        </div>
      </div>

        <Swiper
            spaceBetween={50}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            loop={true}
            autoplay={
              {
                delay:1500,
                disableOnInteraction:false
              }
            }
            modules={[Autoplay]}
            pagination={{ clickable: true }}
            observer ={true}
            observeParents={true}
            parallax={true}
            breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 3,
                }
              }}
            className="card-list py-4 px-3"
            id="articles-cards-slider"
            >
            {blogData.map((blogData,ind)=>{
              return (<>
                <SwiperSlide key={ind}>
                  <CardBox cardImg={blogData.cardImg} title={blogData.title} content={blogData.content} anchor={blogData.anchor}/>
                </SwiperSlide>
              </>)
            })}
            
        </Swiper>
        
    </div>
  )
}

export default ServicesCarousel