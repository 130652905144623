import React from 'react'
import "./Footer.css"
import { NavLink } from 'react-router-dom'

function Footer() {
  return (
    <>
    <div className='Footer-container  standard-padding-space'>
        <div className='container mt-4'>
          <div className='text-content text-center d-flex flex-column align-items-center gap-4'>
          <img src='/images/icons/footer-logo.svg' alt='navigation bar logo' loading='lazy'/>
            <p className='body-paragraph w-50 mt-3'>
            At Excel World Immigration, we're dedicated to turning your dreams into reality. Trust us to guide you on your journey to a new life in Canada with expert advice and personalized support.
            </p>

            <div className='d-flex align-items-center jusstify-content-center gap-3 mt-3'>
              <a
              target="blank" href="https://www.facebook.com/profile.php?id=100084682533065"
              ><img className='social-icons' alt='social icon' src='/images/icons/fb-icon.png' loading='lazy'/>
              </a>
              <a
              target="blank" href="https://www.linkedin.com/company/excel-world-immigration-and-education/about/"
              ><img className='social-icons' alt='social icon' src='/images/icons/linked-icon.png' loading='lazy'/>
              </a>
              <a
              target="blank" href="https://www.instagram.com/excelimmigration/"
              ><img className='social-icons' alt='social icon' src='/images/icons/insta-icon.png' loading='lazy'/>
              </a>
              <a
              target="blank" href="https://x.com/ExcelWorldImmi"
              ><img className='social-icons' alt='social icon' src='/images/icons/twitter-icon.png' loading='lazy'/>
              </a>
            </div>

            <div className='d-flex align-items-center justify-content-evenly flex-column flex-lg-row gap-3 w-75 w-100 my-4'>
              <NavLink to={"/about"} className="anchor" exact activeClassName="active">
              About us
              </NavLink>
              <NavLink to={"/process"} className="anchor" exact activeClassName="active">
              Process
              </NavLink>
              <NavLink to={"/immigration"} className="anchor" exact activeClassName="active">
              Services
              </NavLink>
              <NavLink to={"/express-entry"} className="anchor" exact activeClassName="active">
              Express Entry
              </NavLink>
              <NavLink to={"/refused-visa"} className="anchor" exact activeClassName="active">
              Refused Visa 
              </NavLink>
              <NavLink to={"/citizenship"} className="anchor" exact activeClassName="active">
              Citizenship
              </NavLink>
              <NavLink to={"/news"} className="anchor" exact activeClassName="active">
              News 
              </NavLink>
              <NavLink to={"/contact"} className="anchor" exact activeClassName="active">
              Contact 
              </NavLink>
            </div>

            <div className='w-100 my-4'>
              <div className='row gy-3'>
                <div className='col-lg-3'>
                  <div className='d-flex align-items-center justify-content-center flex-column w-100'>
                    <img className='mb-3' src='/images/icons/phone-footer-icon.svg' alt='phone icon' loading='lazy'/>
                    <span>
                      {/* <a className='anchor' href='tel:905 624 6929'>905 624 6929</a> / */}
                     <a className='anchor' href='tel:(437) 422 0422'>(437) 422 0422</a>
                     </span>
                  </div>
                </div>
                
                <div className='col-lg-3'>
                  <div className='d-flex align-items-center justify-content-center flex-column w-100'>
                    <img className='mb-3' src='/images/icons/location-footer-icon.svg' alt='phone icon' loading='lazy'/>
                    <a  
                      href="https://www.google.com/maps/place/Excel+World+Immigration+and+Education+Consultant+Inc/@43.6929771,-79.6998769,17z/data=!3m1!4b1!4m6!3m5!1s0x882b3f88ec487b5d:0xb1a49d3b82ea67e1!8m2!3d43.6929733!4d-79.697302!16s%2Fg%2F11h5md9dks?entry=ttu" 
                      target='blank'
                      className='anchor'>
                        8 Strathearn Avenue, Unit #17, Brampton, ON L6T 4L7, Canada</a>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='d-flex align-items-center justify-content-center flex-column w-100'>
                    <img className='mb-3' src='/images/icons/location-footer-icon.svg' alt='phone icon' loading='lazy'/>
                    <a  
                      href="https://www.google.com/maps/place/VCIC+-+Vajra+Canadian+Immigration+Consultancy+Inc./@43.4999072,-79.83853,17z/data=!3m2!4b1!5s0x882b6f51f00e0fb3:0x4fd27565536d9200!4m6!3m5!1s0x23fa858b640b748f:0x9a291866969a391!8m2!3d43.4999033!4d-79.8359551!16s%2Fg%2F11sw_mgx11?entry=ttu" 
                      target='blank'
                      className='anchor'>
                        863 Whitlock Ave Milton, ON L9E 1R8, Canada</a>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='d-flex align-items-center justify-content-center flex-column w-100'>
                    <img className='mb-3' src='/images/icons/mail-footer-icon.svg' alt='phone icon' loading="lazy"/>
                    <a className='anchor' href='mailto:excelworldimmigration@gmail.com'>excelworldimmigration@gmail.com</a>
                  </div>
                </div>

              </div>
            </div>



            
          </div>
        </div>
        </div>
        <div className='py-3' style={{background:"white"}}>

        <div className='d-flex align-items-center justify-content-between flex-column gap-3 flex-lg-row w-100 container' >
            <p className="mb-0">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank" style={{color:"#717C93",textDecoration:"none"}}>Creative Squad </a></p>
            <p className='body-paragraph mb-0' style={{fontSize:"14px",color:"#717C93"}}>Copyright © Excel Immigration 2024 | All Right Reserved</p>   
        </div>
        </div>
        </>
  )
}

export default Footer