import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import Sponsor from '../component/Sponsor/Sponsor'
import Contactform from '../component/Contact Form/Contactform'
import FooterNew from "../component/FooterNew/Footer"
import CardContent from '../component/CardContent/CardContent'
import { Helmet } from 'react-helmet'

const dataChoose = [
  {
    title:"1.	Expertise: ",
    content:"Benefit from our deep knowledge and experience in Canadian immigration laws and processes."
  },
  {
    title:"2.	Personalized Service: ",
    content:"Receive customized guidance tailored to your unique immigration needs."
  },
  {
    title:"3.	Success Track Record: ",
    content:"Trust our proven track record of helping countless individuals and families successfully immigrate to Canada."
  },
]

function About() {
    return (
      <div className="home-page">
        <Helmet>
          <title> 
            Leading Canadian Immigration Consultancy | Excel World Immigration
          </title>
          <meta 
          name='description' 
          content="Excel World Immigration provides reliable, dedicated services to help clients achieve their Canadian dream. Trust our expertise for your successful immigration journey." 
          />
          
        </Helmet>
        
        <HeroBanner 
          subHeading=""
          Heading="About Excel Immigration"
          nextLineSpanHeading=""
          Paragraph="Excel World Immigration and Education Consultants Inc is a Canadian Company in good standing with Government of Canada. The Director and CEO is Member of Immigration Consultants of Canada Regulatory Council ( ICCRC) , an autonomous body nominated by Govt of Canada for regulating Immigration Consultants in Canada."
          btnText=""
          bgImg="/images/creatives/about-hero-bg.jpg"
          btn={false}
          btnRef="/"
          bgEffect={false}
          isCheveron={false}
        />
   <div className='left-gradient-bottom'>
        <CardContent
          heading={"Meet our "}
          spanHeading={"Team"}
          content={""}
        />
        {/* <TextContent
           heading= "Meet the  "
           spanHeading='Founder'
           subHeading=""
           content="Colonel Rocky Mehta, a military veteran, is the founder CEO of the Company. He has about 30 years of experience in management at National and International level. He has served in South Asia, Middle East and United States as part of United Nations HR Staff. He holds a master's degree in Management and actively involved in settlement of new comers to Canada. He is also graduate in Immigration Consultants Course from Humber College, Brampton, Canada and member in good standing of ICCRC."
           btn={false}
           btnText=""
           btnRef='/'
           shiftRow={true}
           img="/images/creatives/about-text-1.png"
           reviewContent={false}
           bottomBoxText={true}
        /> */}
  </div>

      <TextContent
           heading= "Future of "
           spanHeading='Excel World Immigration'
           subHeading=""
           content="We're committed to continuously improving our services to better support your immigration journey. Join us as we look forward to helping even more people achieve their dreams of living in Canada."
           btn={false}
           btnText=""
           btnRef='/'
           shiftRow={false}
           img="/images/creatives/about-text-2.png"
           reviewContent={false}
           visionText={true}
        />
 
   
      <div className='right-gradient'>
         <Sponsor/>
      </div >


      <TextContent
           heading= "Why "
           spanHeading='Choose Us'
           subHeading=""
           content="The Excel World Immigration deals in immigration and settlement services to Canada. Though the Company offers full range of immigration consultancy services, the core competency is in business and entrepreneurs, family class and student settlement services. The Management is backed by an expert team of lawyers and associates at National and International level."
           btn={true}
           btnText="Let's Connect"
           btnRef='/'
           shiftRow={true}
           img="/images/creatives/about-text-3.png"
           reviewContent={false}
           titleList={true}
           titleListContent={dataChoose}
        />
        <div className='right-gradient'>
         <Contactform/>
        </div>
  
  
        <FooterNew/>
  </div>
    )
  }
  
  export default About