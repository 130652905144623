import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import FooterNew from "../component/FooterNew/Footer"
import NewsLetter from '../component/NewsLetter/NewsLetter'
import Sponsor from '../component/Sponsor/Sponsor'
import WhyChooseUs from '../component/WhyChooseUs/WhyChooseUs'
import Contactform from '../component/Contact Form/Contactform'
import { Helmet } from 'react-helmet'

function Process() {
    return (
        <div className="home-page">
          <Helmet>
          <title> 
            Expert Canadian Immigration Process Guidance | Excel World
          </title>
          <meta 
          name='description' 
          content="Excel World ensures your smooth Canadian immigration with expert guidance, document verification, and interview preparation. Trust us for a seamless journey." 
          />
          
        </Helmet>
          
          <HeroBanner 
            subHeading=""
            Heading="Our Process"
            nextLineSpanHeading=""
            Paragraph="At Excel, we follow process which is suggested by Government of Canada. Your application is important for us and we make sure we help you with each step to have your family settled in Canada. Your success is our success and we make it possible by following best possible options available for specific case."
            btnText=""
            bgImg="/images/creatives/process-hero-bg.jpg"
            btn={false}
            btnRef="/"
            bgEffect={false}
            isCheveron={false}
          />
     <div className='left-gradient-bottom'>
          <TextContent
             heading= "Application "
             spanHeading='Review & Submission'
             subHeading=""
             content="Canadian Immigration is a complex multi stage process. First step of process is review of your documents & assess your eligibility to immigrate to Canada. Based on personal, financial & other aspects of your profile, we suggest quick & easy way to move to Canada. It is very important to apply on time with perfectly filled & verified application. Most of the rejections are due to invalid / missing data in your application. Our team has vast experience in filling up applications & make sure its not rejected in early stage. Our verification team verify your application and documents before it is submitted to make sure your application gets processed speedily. The properly filled and supported applications have greater chances of approval and likely to be waived off for personal interview."
             btn={true}
             btnText="Let's Connect"
             btnRef='/'
             shiftRow={true}
             img="/images/creatives/process-text-1.png"
             reviewContent={false}
            
          />
    </div>

    <Sponsor/>
  
        <TextContent
             heading= "Medical & "
             spanHeading='Interview Preparation'
             subHeading=""
             content={"The last stage of Application is the medical clearance. The Medical is done by medical practitioners nominated by Canadian Govt. Our team will guide you to schedule your medical test with appropriate licenced medical practitioner. If for any reason, your medical test does not meet the standards set by Canadian Government, , our team help you to deal with it.\n Most of our applications get waived from personal interview due to our perfectly filled application. For any reason, if your application requires personal interview, then we help you to give guidance for facing the interview. Our expert team will walk you through full interview process and make sure you can satisfy the Visa Officer’s concerns."}
             btn={true}
             btnText="Let's Connect"
             btnRef='/'
             shiftRow={false}
             img="/images/creatives/process-text-2.png"
             
          />
   
   <WhyChooseUs/>
     
        <div className='left-gradient-bottom'>
            <TextContent
                heading= "Settlement  "
                spanHeading='Advice'
                subHeading=""
                content={"Once you get visa, our team will link you up with Settlement Services established in your Country by Government of Canada to help new immigrants to prepare and settle in Canada. We understand that settling in new country is a big challenge. We stand by you and make sure that you & your family are getting ready for a new life in Canada.it.\n Our team helps you to understand housing, health, banking, culture and other systems before you come here. Advanced Orientation Sessions by our counselors impart guidance and assistance about living in Canada, their social life and culture banking, life insurance, education, health services, traveling and much more. These sessions help our clients acclimatize to the life in Canada, without wasting their valuable time. We also offer counseling and arrange for your travel, pick up from Airport, temporary housing on arrival, documentation for medical, SIN Card and PR card. Driving License guidance and finding educational institutes for your children. You can always reach us for any of your future needs like PR Card renewals, acquiring Canadian Citizenship etc."}
                btn={true}
                btnText="Let's Connect"
                btnRef='/'
                shiftRow={true}
                img="/images/creatives/process-text-3.png"
                reviewContent={false}
            />
        </div >

            <Contactform/>
    
    
          <FooterNew/>
    </div>
      )
}

export default Process