import React, { useState,useRef,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({slideState,removeNavLinks,darkBg=false}) => {
  const [menuState, setMenuState] = useState(false);
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const navigate = useNavigate()
  const clickCountRef = useRef(0);
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk]);


const onClickService = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpen(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpen(false)
        navigate("/immigration");
        clickCountRef.current = 0;
      }
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };
  

  return (
    <div className={`header ${!darkBg && "mt-3"} d-flex flex-column py-2 pt-1 ${darkBg && "px-4 pt-3"}`} style={{background:`${darkBg?"grey":"transparent"}`}}>
      <div className="pre-header-text w-100 text-center text-lg-end d-flex justify-content-between mb-3">
        
          <div className="d-flex gap-2">
            <a 
            target="blank" href="https://www.facebook.com/profile.php?id=100084682533065"
            ><img src="/images/icons/nav-fb-icon.png" alt="social icon" className="header-social-btn"/></a>
            <a 
            target="blank" href="https://www.linkedin.com/company/excel-world-immigration-and-education/about/"
            ><img src="/images/icons/nav-linked-icon.svg" alt="social icon" className="header-social-btn"/></a>
            <a 
            target="blank" href="https://www.instagram.com/excelimmigration/"
            ><img src="/images/icons/nav-insta-icon.svg" alt="social icon" className="header-social-btn"/></a>
            <a 
            target="blank" href="https://x.com/ExcelWorldImmi"
            ><img src="/images/icons/nav-twiter-icon.png" alt="social icon" className="header-social-btn"/></a>
              
          </div>
          <p className="body-paragraph mb-0" style={{color:'white'}}>Talk to Our Expert : <a href="tel:(437) 422 0422" className="color-red " style={{textDecoration:"none"}}>(437) 422 0422</a></p>
      </div>
      <div className="header-wrapper">
        <nav className="navbar d-md-flex flex-md-row d-block py-0">
          <nav className="navbar-container px-lg-0 px-2 ">
          
            <div className="nav-elements d-flex justify-content-between align-items-center px-lg-2">
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <img src="/images/icons/nav-logo.svg" alt="navigation logo" className="nav-logo" onClick={()=>{!removeNavLinks && navigate("/")}} style={{cursor:"pointer"}}/>
              
              {
                !removeNavLinks && 
                <nav>
                  <ul
                    className="nav-list d-lg-flex gap-xl-4 gap-lg-3 align-items-center d-none main-nav"
                    id="js-menu"
                  >
                    <li>
                      <Link to={"/about"} className="nav-links font-Open-Sans">
                      About us
                      </Link>
                    </li>
                    <li>
                      <Link to={"/process"} className="nav-links font-Open-Sans">
                      Process
                      </Link>
                    </li>

                    <div className="navigation-dropdown" ref={dropdownRef}>
                    <li onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} >

                        <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColor(true)}} onMouseOut={()=>{setChangeArrowColor(false)}}>
                            <Link to={"/immigration"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}} style={{color:`${changeArrowcolor ? "#AD1E17":"white"}`}}>Immigration</Link>
                            <img alt="arrow icon" src={`/images/icons/drop-icon-${changeArrowcolor ? "w":"w"}.svg`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                        </div>
                        <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDesk(false)}}>
                
                          <Link to={"/immigration/refugee-cases"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Refugee Cases</p>
                          </Link>
                          <Link to={"/immigration/refused-visa"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Refused Visa</p>
                          </Link>
                          <Link to="/immigration/super-visa" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Super Visa</p>
                          </Link>
                          {/* <Link to="/immigration/quebec-skilled-worker" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Quebec Skilled Worker</p>
                          </Link>
                          <Link to="/immigration/investor-program" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Investor Program</p>
                          </Link>
                          <Link to="/immigration/entrepreneur-program" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Entrepreneur Program</p>
                          </Link>
                          <Link to="/immigration/self-employed-program" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Self Employed Program</p>
                          </Link>
                          <Link to="/immigration/family-sponsorship" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Family Sponsorship</p>
                          </Link> */}
                          <Link to="/immigration/sponsoring-spouse" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Sponsoring Spouse</p>
                          </Link>
                          <Link to="/immigration/work-permit" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Work Permit</p>
                          </Link>
                          <Link to="/immigration/visitors-visa" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Visitors Visa</p>
                          </Link>
                          <Link to="/immigration/live-in-caregivers" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Live-in Caregivers</p>
                          </Link>
                          <Link to="/immigration/student-visa" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Student Program</p>
                          </Link>
                          <Link to="/immigration/pnp-program" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>PNP Program</p>
                          </Link>

                          
                        </div>
                      </li>
                      </div>



                    <li>
                      <Link to={"/express-entry"} className="nav-links font-Open-Sans">
                        
                      Express Entry
                      </Link>
                    </li>
                    <li>
                      <Link to={"/refused-visa"} className="nav-links font-Open-Sans">
                      Refused Visa
                      </Link>
                    </li>
                    <li>
                      <Link to={"/citizenship"} className="nav-links font-Open-Sans">
                      Citizenship
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact"} className="nav-links font-Open-Sans">
                      Contact
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={"/"} className="nav-links font-Open-Sans">
                      News
                      </Link>
                    </li>
                    <li>
                      <Link to={"/"} className="nav-links font-Open-Sans">
                      Contact
                      </Link>
                    </li> */}
                  </ul>
                </nav>
              }

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-lg-none d-inline"
              >
                <img
                  src="/images/icons/hamburger-menu.png"
                  className="img-fluid menu-btn"
                  alt="menu"
                />
                
              </label>
             

              <div className="nav-buttton ml-5 d-lg-flex d-none gap-3 extras-btns">
                <button className="genral-btn font-Open-Sans" style={{fontSize:"14px",fontWeight:"600"}}  onClick={()=>{navigate("/assesment")}}>
                Free Assesment
                </button>

              </div>
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100">
            <div className="py-3 d-lg-none" style={{ display: menuState ? "block" : "none" }}>
            <ul
              className="resp-nav-list d-lg-none w-100 gap-4 hs-dropdown [--trigger:hover] pl-4"
              style={{ display: menuState ? "block" : "none" }}
            >
              <li>
                <Link to={"/about"} className="nav-links font-Open-Sans">
                  About us
                </Link>
              </li>
              <li>
                <Link to={"/process"} className="nav-links font-Open-Sans">
                Process
                </Link>
              </li>
               <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickService} >
                      <a className="nav-links " >Immigration</a>
                      <img src='/images/icons/drop-icon-b.svg' alt="dropdown icon" className={isDropdownOpen ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpen ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    <Link to={"/immigration/refugee-cases"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Refugee Cases</p>
                    </Link>
                    <Link to={"/immigration/refused-visa"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Refused Visa</p>
                    </Link>
                    <Link to="/immigration/super-visa" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Super Visa</p>
                    </Link>
                    {/* <Link to={"/immigration/federal-skilled-worker"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>federal skilled worker</p>
                    </Link>
                    <Link to="/immigration/quebec-skilled-worker" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Quebec Skilled Worker</p>
                    </Link>
                    <Link to="/immigration/investor-program" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Investor Program</p>
                    </Link>
                    <Link to="/immigration/entrepreneur-program" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Entrepreneur Program</p>
                    </Link>
                    <Link to="/immigration/self-employed-program" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Self Employed Program</p>
                    </Link>
                    <Link to="/immigration/family-sponsorship" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Family Sponsorship</p>
                    </Link> */}
                    <Link to="/immigration/sponsoring-spouse" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Sponsoring Spouse</p>
                    </Link>
                    <Link to="/immigration/work-permit" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Work Permit</p>
                    </Link>
                    <Link to="/immigration/visitors-visa" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Visitors Visa</p>
                    </Link>
                    <Link to="/immigration/live-in-caregivers" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Live-in Caregivers</p>
                    </Link>
                    <Link to="/immigration/student-visa" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Student Program</p>
                    </Link>
                    <Link to="/immigration/pnp-program" className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>PNP Program</p>
                    </Link>

                  </div>
                </div>
                </li>
              <li>
                <Link to={"/express-entry"} className="nav-links font-Open-Sans">
                Express Entry
                </Link>
              </li>
              <li>
                <Link to={"/refused-visa"} className="nav-links font-Open-Sans">
                Refused Visa
                </Link>
              </li>
              <li>
                <Link to={"/citizenship"} className="nav-links font-Open-Sans">
                Citizenship 
                </Link>
              </li>
              <li>
                <Link to={"/news"} className="nav-links font-Open-Sans">
                News 
                </Link>
              </li>
              <li>
                <Link to={"/contact"} className="nav-links font-Open-Sans">
                Contact 
                </Link>
              </li>
            </ul>
            <div className="nav-buttton flex-wrap d-flex gap-4 extras-btns">
                <button className="genral-btn font-Open-Sans" style={{fontSize:"14px",fontWeight:"600"}} onClick={()=>{navigate("/assesment")}} >
                Free Assesment
                </button>
            
               
            </div>
            </div>
          </nav>
        </nav>
      </div>
    </div>
  );
};

export default Header; 