import './App.css';
import "./bootstrap.min.css"
import { Routes, Route , Navigate } from 'react-router-dom';
import Home from './screens/Home';

import About from './screens/About';
import Process from './screens/Process';
import Immigration from './screens/Immigration';
import Immigrationinner from './screens/Immigrationinner';
import ExpressEntry from './screens/ExpressEntry';
import RefusedVisa from './screens/RefusedVisa';
import Citizenship from './screens/Citizenship';
import Assesment from './screens/Assesment';
import News from './screens/News';
import BlogInner from './screens/BlogInner';
import Contact from './screens/Contact';

import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";

// import PrivacyPolicy from "./screens/PrivacyPolicy"

import NotFound from './screens/NotFound';

import ThankYou from './screens/ThankYou';

import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./component/LocationPages_/LocationPages"
import ECards from './screens/ECards';


const rockyDetails = {
  image:"/images/creatives/colonel.png",
  name:"Colonel Rocky Mehta, RCIC",
  rank:"Founder & Director"
}
const vajinderDetails = {
  image:"/images/creatives/about-card-3.jpg",
  name:"Varjinder Dhariwal, RCIC",
  rank:""
}

function App() {
  return (
    <div >
      <Routes>
      
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" replace={true} />}/>
        <Route path="/about" element={<About />} />
        <Route path="/process" element={<Process />} />
        <Route path="/immigration" element={<Immigration />} />
        <Route path="/immigration/:id" element={<Immigrationinner />} />
        <Route path="/express-entry" element={<ExpressEntry />} />
        <Route path="/refused-visa" element={<RefusedVisa />} />
        <Route path="/citizenship" element={<Citizenship />} />
        <Route path="/assesment" element={<Assesment />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<BlogInner />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/e-card" element={<ECards details={rockyDetails}/>} />
        <Route path="/e-card-vajinder" element={<ECards details={vajinderDetails} />} />

        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>
        <Route path="*" element={<NotFound />} />
       
        <Route path="/thank-you" element={<ThankYou />} />
      </Routes>
    </div>
  );
}

export default App;
