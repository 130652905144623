import React from 'react'
import "./WhyChooseUs.css"

const chooseData = [
    {img:"/images/icons/choose-1.svg",title:"FREE ASSESSMENT"},
    {img:"/images/icons/choose-2.svg",title:"PROVEN TRACK RECORD"},
    {img:"/images/icons/choose-3.svg",title:"REASONABLE FEES"},
    {img:"/images/icons/choose-4.svg",title:"MULTILINGUAL SUPPORT"},
    {img:"/images/icons/choose-5.svg",title:"PROMPT COMMUNICATION"},
    {img:"/images/icons/choose-6.svg",title:"COMPREHENSIVE GUIDANCE"},
]

function WhyChooseUs() {
  return (
    <div className='why-choose-container standard-padding-space'>
        <div className='container'>
            <div className='text-container text-center'>
                <h3 className='body-heading'>Why Choose <span className='color-red'>Excel World</span></h3>
                <p className='body-paragraph py-3'>Committed to delivering efficient and dependable Immigration services to Canada</p>
            </div>
            <div className='choose-card-list'>
                <div className='row gy-4'>
                    {
                        chooseData.map((ele)=>{
                            return(
                                <div className='col-xxl-2 col-xl-4 col-md-6'>
                                    <div className='card-box m-auto d-flex flex-column justify-content-evenly align-items-center'>
                                        <img src={ele.img} className='card-img' alt={`${ele.title} icon`}/>
                                        <p className='body-paragraph'>{ele.title}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhyChooseUs