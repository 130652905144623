
function TestimonialCard(props) {
 
  return (
    <div className="testimonial-card">
        <div className="testimonial-card-info pb-2">
          <div className="profile-info d-flex flex-column align-items-center justify-content-between gap-4">
              <img src={props.clientImg} alt="user icon" className="user-img"/>
              <h5 className="sub-heading mb-0" style={{color:"#006EAE"}}>{props.clientName}</h5>
              <p className="body-paragraph text-center mb-0">{props.clientReview}</p>
              <img src="/images/icons/stars-icon.svg" alt="qoutation icon" className="user-img"/>
          </div>
        </div>
    </div>
  )
}

export default TestimonialCard