import React from 'react'
import CheckList from '../CheckList/CheckList'
import "./ReasonedText.css"

const dataarr1 = [
   " Passports and travel documents,",
   "Birth, marriage, final divorce, annulment, separation or death certificates", 
   "Visas",
   "Police certificates.",
   "Diplomas, degrees, and apprenticeship or trade papers"
]

const dataArr2 = [
    "Be forbidden to enter Canada for at least five years, ",
    "Be charged with a crime or",
    "Have a permanent record of fraud with CIC,",
    "Be removed from Canada.",
    "Have your status as a permanent resident or Canadian citizen taken away,"
]

function ReasonedText() {
  return (
    <div className='ReasonedText-container standard-padding-space'>
        <div className='container'>
            <div className='text-content text-center'>
                <h3 className='body-heading mb-5'>Common Reasons For <span className='color-red body-heading'>Refusal Of Visa</span></h3>

                <div className='text-start my-5'>

                
                <h5 className='sub-heading'>Document Fraud (Misrepresentation)</h5>
                <p className='body-paragraph mb-4'>It is a serious crime to lie, or to send false information or documents when you deal with Citizenship and Immigration Canada (CIC). This is fraud. It is called “misrepresentation.” Document fraud can involve either false or altered documents, such as:</p>

                <div className='box'>
                    <CheckList
                        dataArr={dataarr1}
                        row={12}
                        content='start'
                    />

                </div>
                <p className='body-paragraph my-4'>If you lie on an application or in an interview with a CIC officer, this is also fraud and a crime. If you send false documents or information, CIC will refuse your application. You could also:</p>
                <div className='box'>
                    <CheckList
                        dataArr={dataArr2}
                        row={12}
                        content='start'
                    />

                </div>
                <h5 className='sub-heading mt-5'>Health</h5>
                <p className='body-paragraph mb-4'>You can be found inadmissible on health grounds. As part of the review process, immigration officers determine whether an individual is likely to place excessive demand on health or social services.</p>
                
                <div className='row '>
                    <div className='col-lg-6'>
                        <h5 className='sub-heading'>Criminality</h5>
                        <p className='body-paragraph mb-4'>You are inadmissible to Canada if you have a criminal background.</p>
                    </div>
                    <div className='col-lg-6'>
                        <h5 className='sub-heading'>Poor documentation</h5>
                        <p className='body-paragraph mb-4'>Poorly prepared supporting documents and/or are irrelevant</p>
                    </div>
                    <div className='col-lg-6'>
                        <h5 className='sub-heading'>Visa Category</h5>
                        <p className='body-paragraph mb-4'>Not applying under correct visa category</p>
                    </div>
                    <div className='col-lg-6'>
                        <h5 className='sub-heading'>Not meeting eligibility</h5>
                        <p className='body-paragraph mb-4'>without proper assessment applying for visa while not meeting the eligibility</p>
                    </div>
                </div>
                <h5 className='sub-heading'>Inadequate financial resources</h5>
                <p className='body-paragraph mb-4'>You are inadmissible for financial reasons if you are or will be unable or unwilling to support yourself or any other person who is dependent on you, and have not satisfied the officer that adequate arrangements for care and support, other than those that involve social assistance, have been made</p>
                <h5 className='sub-heading'>Failure to attach documents</h5>
                <p className='body-paragraph mb-4'>You have prepared proper supporting documents but fail to attach them with application</p>
                <p className='body-paragraph mb-4'>Our Team will review your past application and recommend the options for re-applying.</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default ReasonedText