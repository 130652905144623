import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import FooterNew from "../component/FooterNew/Footer"
import ImmigrationServices from '../component/ImmigrationServices/ImmigrationServices'
import { Container } from 'react-bootstrap'
import Sponsor from '../component/Sponsor/Sponsor'
import WhyChooseUs from '../component/WhyChooseUs/WhyChooseUs'
import Contactform from '../component/Contact Form/Contactform'
import { Helmet } from 'react-helmet'

// const firstRowData = [
//     {
//         cardImg:"/images/creatives/immigration-card-1.png",
//         title:"Federal Skilled Worker",
//         content:"Immigrate to Canada based on your skills and experience, supported by our thorough federal skilled worker program assistance.",
//         anchor:"/immigration/federal-skilled-worker"
//     },
//     {
//         cardImg:"/images/creatives/immigration-card-2.png",
//         title:"Quebec Skilled Worker",
//         content:"Move to Quebec based on your skills and qualifications, guided by our specialized Quebec immigration expertise.",
//         anchor:"/immigration/quebec-skilled-worker"
//     },
//     {
//         cardImg:"/images/creatives/immigration-card-3.png",
//         title:"Investor Program",
//         content:"Invest in Canada and become a permanent resident through our tailored investor immigration services.",
//         anchor:"/immigration/investor-program"
//     },

// ]
const secondRowData = [
    {
        cardImg:"/images/creatives/service-card-6.png",
        title:"Super Visa",
        content:"The Super Visa allows parents & grandparents to visit their family in Canada for extended periods.",
        anchor:"/immigration/super-visa"
    },
    {
        cardImg:"/images/creatives/service-card-4.png",
        title:"Refugee Cases",
        content:"Seeking asylum in Canada is a serious and often urgent matter and we provide the right assistance for it!",
        anchor:"/immigration/refugee-cases"
    },
    {
        cardImg:"/images/creatives/service-card-5.png",
        title:"Refused Visa",
        content:"If your Visa Application has been refused, don't worry - we can help!",
        anchor:"/immigration/refused-visa"
    },

]
const thirdRowData = [
    {
        cardImg:"/images/creatives/immigration-card-7.png",
        title:"Sponsoring Spouse",
        content:"Reunite with your spouse in Canada through our efficient and supportive sponsorship services.",
        anchor:"/immigration/sponsoring-spouse"
    },
    {
        cardImg:"/images/creatives/immigration-card-8.png",
        title:"Work Permit",
        content:"Obtain a work permit and gain valuable Canadian work experience under our guidance and support.",
        anchor:"/immigration/work-permit"
    },
    {
        cardImg:"/images/creatives/immigration-card-9.png",
        title:"Visitors Visa",
        content:"Visit Canada for leisure, business, or family visits, facilitated by our streamlined visa application process.",
        anchor:"/immigration/visitors-visa"
    },

]
const forthRowData = [
    {
        cardImg:"/images/creatives/immigration-card-10.png",
        title:"Live-in Caregivers",
        content:"Care for families in Canada while living with them, facilitated by our compassionate and experienced immigration assistance.",
        anchor:"/immigration/live-in-caregivers",
    },
    {
        cardImg:"/images/creatives/immigration-card-11.png",
        title:"Student Program",
        content:"Pursue your education goals in Canada with ease, supported by our knowledgeable team specializing in student visas.",
        anchor:"/immigration/student-visa",
    },
    {
        cardImg:"/images/creatives/immigration-card-12.png",
        title:"PNP Program",
        content:"Move to a specific province in Canada and enjoy all it has to offer with our expert guidance on provincial nomination.",
        anchor:"/immigration/pnp-program",
    },

]

function Immigration() {
  return (
    <div className="home-page">
         <Helmet>
          <title> 
          Comprehensive Canadian Immigration Services | Excel World Immigration
          </title>
          <meta 
          name='description' 
          content="Excel World offers expert  Immigration guidance for visas, super visas, refugee cases, spousal sponsorship, work permits, caregivers, student visas, and PNP programs." 
          />
          
        </Helmet>
        
    <HeroBanner 
      subHeading=""
      Heading="Immigration"
      nextLineSpanHeading=""
      Paragraph="At Excel, we follow process which is suggested by Government of Canada. Your application is important for us and we make sure we help you with each step to have your family settled in Canada. Your success is our success and we make it possible by following best possible options available for specific case."
      btnText=""
      bgImg="/images/creatives/immigration-hero-bg.jpg"
      btn={false}
      btnRef="/"
      bgEffect={false}
      isCheveron={false}
    />
    {/* <div className='left-gradient-bottom'>
        <ImmigrationServices
            dataArr={firstRowData}
        />
    </div> */}
    <ImmigrationServices
        dataArr={secondRowData}
    />
    <Sponsor/>
    <div className='left-gradient-bottom'>
        <ImmigrationServices
            dataArr={thirdRowData}
        />
    </div>
    <WhyChooseUs/>
    <ImmigrationServices
        dataArr={forthRowData}
    />

        <Contactform/>
 


        <FooterNew/>
    </div>
  )
}

export default Immigration