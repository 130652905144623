import React from 'react'
import "./AdditionalResources.css"
import CheckList from '../CheckList/CheckList'

const listData = [
    {
        content: "Express Entry How it Works ?",
        link:"https://ircc.canada.ca/english/immigrate/express/express-entry/sign-up.asp"
    },
    {
        content: "Sign up for Express Entry Latest News",
        link:"https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents.html"
    },
    {
        content: "Documents for Express Entry",
        link:"https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/works.html"
    },
    {
        content: "Instructions that explain how the rounds of invitations work",
        link:"https://www.canada.ca/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions.html#mi-express"
    },
    
    
    
]

function AdditionalResources() {
  return (
    <div className='AdditionalResources-container standard-padding-space'>
        <div className='container'>
            <div className='main-box text-center'>
                <h3 className='body-heading mb-4'>Additional <span className='body-heading color-red'>Resources</span></h3>
                <CheckList
                    dataArr={listData}
                    row={6}
                    anchorList={true}
                />
            </div>
        </div>
    </div>
  )
}

export default AdditionalResources