import React, { useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Contactform from '../component/Contact Form/Contactform'
import {Helmet} from "react-helmet"
import ServicesCarousel from '../component/ServicesCarousel/ServicesCarousel'
import NewsLetter from '../component/NewsLetter/NewsLetter'
import FooterNew from "../component/FooterNew/Footer"

function Home() {
    
  const [blogsShow , setBlogsShow] = useState(true)
  console.log(blogsShow);
  
  return (
    <div className="home-page">
        <Helmet>
          <title> 
          Top Immigration Consultants Service in Canada | Excel World Immigration
          </title>
          <meta 
          name='description' 
          content="Looking for top immigration consultants in Canada? Get expert guidance and successful immigration services for your move to Canada. Contact us today!" 
          />
          
        </Helmet>
        
      <HeroBanner 
        subHeading=""
        Heading="Top Immigration consultants in Canada"
        nextLineSpanHeading=""
        Paragraph="We're here to guide you every step of the way. Our experienced team of experts provide the best Immigration service in Canada to make your journey to a new country as smooth and stress-free as possible."
        btnText="Explore Details"
        bgImg="/images/creatives/home-hero-bg.jpg"
        btn={true}
        btnRef="/"
        bgEffect={false}
        bgVideo={false}
        textStart={true}
      />
    <div className='left-gradient-bottom'>
      <TextContent
         heading= "Welcome to "
         spanHeading="Excel Immigration"
         content="Excel World Education and Immigration Consultants Inc was established in Canada with the primary objective of providing quality Canadian Immigration Consultancy services for migrants from all corners of the Globe. Our Director is member in good standing with the Immigration Consultants of Canada Regulatory Council and possesses legal education combined with several years of work experience dealing with Immigration matters and have helped several clients to successfully immigrate from various parts of the world."
         btn={true}
         btnText="About Us"
         btnRef='/about'
         shiftRow={true}
         img="/images/creatives/home-text-1.png"
         reviewContent={false}
      />
    </div>
      <ServicesCarousel
        heading={"Our "}
        spanHeading={"Immigration Services"}
        content={"We offer a variety of pathways to help you make Canada your new home. Here are some of our key services:"}
      />

    <div className='right-gradient'>
      <TextContent
         heading= "Looking to "
         spanHeading="Study Abroad?"
         content="We provide international students a pathway to study in leading universities. But it's more than a pathway. We go beyond other foundation programmes - helping you to grow as a person and as a thinker, providing an experience that will stay with you for life."
         btn={true}
         btnText="Read More"
         btnRef='/'
         shiftRow={false}
         img="/images/creatives/home-text-2.png"
         reviewContent={false}
      />
    </div>

     
      <Testimonials/>

    {
      blogsShow &&
      <div className='right-upper-gradient'>
      <CardCarousel
        subHeading=""
        heading="Most Popular Blogs"
        content={"Stay informed and get tips from our most popular blog posts. Discover everything you need to know about immigrating to Canada and making the most of your new life here."}
        showBlogsSection={setBlogsShow}
      />
      </div>
    }

      <NewsLetter/>
      <div className='right-gradient'>
      <Contactform/>
      </div>

    <FooterNew/>
      

      </div>
  )
}

export default Home