import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import FooterNew from '../component/FooterNew/Footer'
import Contactform from '../component/Contact Form/Contactform'
import CitizenshipText from '../component/CitizenshipText/CitizenshipText'
import { Helmet } from 'react-helmet'

function Citizenship() {
  return (
    <div>
      <Helmet>
          <title> 
          Canada Citizenship Application | Requirements & Process
          </title>
          <meta 
          name='description' 
          content="Learn about applying for Canadian citizenship, including residency requirements and the citizenship test. Follow correct procedures for a successful application."
          />
          
        </Helmet>
        <HeroBanner 
            subHeading=""
            Heading="Citizenship"
            nextLineSpanHeading=""
            Paragraph=""
            btnText=""
            bgImg="/images/creatives/citizenship-hero-bg.jpg"
            btn={false}
            btnRef="/"
            bgEffect={false}
            isCheveron={false}
        />
        <div className='left-gradient'>
            <CitizenshipText/>
        </div>

        <div className='right-gradient-bottom'>
         <Contactform/>
        </div>

        <FooterNew/>
    </div>
  )
}

export default Citizenship