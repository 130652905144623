import React from 'react'
import { useNavigate } from 'react-router-dom';

function ImmigrationServicesCard(props) {
    const navigate = useNavigate();
  return (
    <div className='ImmigrationServicesCard-container'>
        <div>
            <img src={props.cardImg} alt={props.title} />
            <div className="card-text-content">
            <h2>{props.title}</h2>
            <p>{props.content}</p>
            </div>
        </div>
        <a onClick={()=>{navigate(props.anchor)}} className="read-more-anchor color-red">Learn More <img src="/images/icons/carousel-btn-icon.svg" className="btn-icon" alt="btn-icon"/></a>
    </div>
  )
}

export default ImmigrationServicesCard