import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import AssessmentForm from '../component/AssessmentForm/AssessmentForm'
import FooterNew from '../component/FooterNew/Footer'
function Assesment() {
  return (
    <div>
      
         <HeroBanner 
            subHeading=""
            Heading="Free Assessment"
            nextLineSpanHeading=""
            Paragraph="Information provided in completed Online Assessment Questionnaires will be kept strictly confidential. Incomplete Online Assessment Questionnaires and/or Online Assessment Questionnaires containing no personal/contact information will NOT be assessed by us."
            btnText=""
            bgImg="/images/creatives/assesment-hero-bg.jpg"
            btn={false}
            btnRef="/"
            bgEffect={false}
            isCheveron={false}
        />

        <AssessmentForm/>
        
        <FooterNew/>
    </div>
  )
}

export default Assesment