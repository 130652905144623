import React from 'react'
import Header from '../component/Header/Header'
import ECardSection from "../component/ECardSection/ECardSection"
import Footer from '../component/FooterNew/Footer'
import { Helmet } from 'react-helmet'

function ECards({details}) {
  return (
    <div>
      <Helmet>
        <meta property="og:image" content="https://www.excelworldimmigration.com/images/creatives/og-image-e-card.png" />
      </Helmet>
        <Header
            darkBg={true}
        />

        <ECardSection
          image={details.image}
          name={details.name}
          rank={details.rank}
        />

        <Footer/>
    </div>
  )
}

export default ECards